import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  container: {
    padding: "36px",
    "@media screen and (max-width: 412px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  logo: {
    width: "100%",
    height: "",
    padding: "3rem 0 1rem 0",
  },
  logintitle: {
    marginTop: "5px",
    fontWeight: "bold",
    color: "#00205b",
  },
  label: {
    marginTop: "60px",
    marginBlockEnd: "-12px",
    marginLeft: "10px",
    color: "#00205b",
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "bold",
  },
  input: {
    borderRadius: "10px",
  },
  MsgError: {
    marginTop: "-8px",
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  captcha: {
    marginTop: "30px",
    width: theme.transitions,
    flexGrow: 0,
    flexBasis: 100,
    justifyContent: "center",
  },
  Recaptcha: {
    "@media screen and (max-width: 425px)": {
      transform: "scale(0.9)",
      transformOrigin: "0 0",
      transformBox: "fill-box",
      "&::-webkit-transform-origin": "0 0",
    },
    "@media screen and (max-width: 395px)": {
      transform: "scale(0.8)",
      transformOrigin: "0 0",
      transformBox: "fill-box",
      "&::-webkit-transform-origin": "0 0",
    },
    "@media screen and (max-width: 365px)": {
      transform: "scale(0.7)",
      transformOrigin: "0 0",
      transformBox: "fill-box",
      "&::-webkit-transform-origin": "0 0",
    },
    "@media screen and (max-width: 325px)": {
      transform: "scale(0.65)",
      transformOrigin: "0 0",
      "&::-webkit-transform-origin": "0 0",
    },
    "@media screen and (max-width: 315px)": {
      transform: "scale(0.6)",
      transformOrigin: "0 0",
      "&::-webkit-transform-origin": "0 0",
    },

    "@media screen and (max-width: 250px)": {
      transform: "scale(0.5)",
      transformOrigin: "0 0",
      "&::-webkit-transform-origin": "0 0",
    },
  },
  buttons_container: {
    display: "flex",
    justifyContent: "flex-end",
    "@media screen and (max-width: 360px)": {
      flexDirection: "column",
    },
  },
  boton: {
    textTransform: "none",
    fontFamily: theme.typography.fontFamily,
    margin: theme.spacing(5, 0),
    // marginBotton: theme.spacing(5),
    // margin: theme.spacing(5, 0, 0),
    backgroundColor: "#00205b",
    color: "white",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    "@media screen and (max-width: 470px)": {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#00207b",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFF",
  },
  modalEnviar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxModalEnviar: {
    margin: "1rem",
    width: "500px",
    minHeight: "200px",
    backgroundColor: "white",
    color: "white",
    borderRadius: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #00205b",
  },
  modal_content: {
    width: "80%",
    margin: "1rem",
  },
  modal_text: {
    fontWeight: "bold",
    color: "#00205b",
  },
  modal_box_button: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    marginTop: "1.5rem",
  },
  modal_button: {
    textTransform: "none",
    color: "white",
    backgroundColor: "#00205b",
    borderRadius: "0.5rem",
    fontWeight: "bold",
    minWidth: "150px",
    height: "45px",
    fontSize: "18px",
    "@media screen and (max-width: 470px)": {
      width: "100%",
      margin: "1rem 0",
    },
    "&:hover": {
      backgroundColor: "#00207b",
    },
  },
  gridError:{ 
    marginTop: "5px", 
    marginBlockEnd: "5px" 
  },
  gridError2:{ 
    marginTop: "-10px", 
    marginBlockEnd: "10px" 
  },
  gridMessage: {
    marginTop: "30px"
  },
  alert_text1:{
    fontSize: "25px",
  },
  alert_text2:{
    fontSize: "25px",
    animation: "$suspensive2 2.5s infinite"
  },
  alert_text3:{
    fontSize: "25px",
    animation: "$suspensive3 2.5s infinite"
  },
  alert_text4:{
    fontSize: "25px",
    animation: "$suspensive4 2.5s infinite"
  },
  alert_text5:{
    fontSize: "25px",
    animation: "$suspensive5 2.5s infinite"
  },
  "@keyframes suspensive2": {
    "0%": {
      opacity: 0,
    },
    "19%": {
      opacity: 0,
    },
    "20%": {
      opacity: 1,
    }
  },
  "@keyframes suspensive3": {
    "0%": {
      opacity: 0,
    },
    "39%": {
      opacity: 0,
    },
    "40%": {
      opacity: 1,
    }
  },
  "@keyframes suspensive4": {
    "0%": {
      opacity: 0,
    },
    "59%": {
      opacity: 0,
    },
    "60%": {
      opacity: 1,
    }
  },
  "@keyframes suspensive5": {
    "0%": {
      opacity: 0,
    },
    "79%": {
      opacity: 0,
    },
    "80%": {
      opacity: 1,
    }
  },
}));
