import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Modal,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./sendEmailModal.styles";

export const SendEmailModal = ({
  openModal,
  closeModal,
  handleSendPdf,
  handleCloseSendCorreo,
  IsValid,
  correo,
  handleInputChange,
  validCorreo,
  errorCorreo,
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal_container}
      open={openModal}
      onClose={() => closeModal(false)}
    >
      <Container className={classes.content + " " + classes.positionCard}>
        <form
          className={classes.form}
          onSubmit={handleSendPdf}
        >
          <FormControl className={classes.select}>
            <label>
              <Typography variant="h6" className={classes.send_title}>
                Ingrese la dirección de email
              </Typography>
            </label>
            <OutlinedInput
              onBlur={IsValid}
              name="correo"
              value={correo}
              onChange={handleInputChange}
              placeholder="example@gmail.com"
            />
          </FormControl>
          {!validCorreo && (
            <Grid className={classes.gridError}>
              <Typography className={classes.MsgError}>
                {errorCorreo}
              </Typography>
            </Grid>
          )}
        </form>
        <Box
          my={2}
          display="flex"
          justifyContent="space-around"
          className={classes.buttons_content}
        >
          <Button
            className={classes.modalButton2}
            variant="contained"
            onClick={handleCloseSendCorreo}
          >
            Cancelar
          </Button>
          <Button
            className={classes.modalButton1}
            variant="contained"
            color="primary"
            onClick={handleSendPdf}
          >
            Enviar
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
