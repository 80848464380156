import {
  Box,
  Button,
  CircularProgress,
  Container,
  InputAdornment,
  Modal,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./walletAndroidModal.styles";
import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useSelector } from "react-redux";

export const WalletAndroidModal = ({
  openModal,
  closeModal,
  readyWallet,
  AddToWallet,
}) => {
  const classes = useStyles();
  const { strapiData } = useSelector((state) => state.strapiData);
  const content = strapiData?.wallet;

  return (
    <Modal
      className={classes.modal_container}
      open={openModal}
      onClose={() => closeModal(false)}
    >
      <Container className={classes.content}>
        <Box>
          <Typography className={classes.info_wallet}>
            <ReactMarkDown 
              children={content?.contenido}
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}/>
          </Typography>
        </Box>
        <Box
          my={3}
          display="flex"
          justifyContent="space-around"
          className={classes.buttons_content}
        >
          <Button
            className={classes.modalButton2}
            variant="contained"
            onClick={() => closeModal(false)}
          >
            Cancelar
          </Button>
          <Button
            className={classes.modalButton1}
            variant="contained"
            color="primary"
            onClick={AddToWallet}
          >
            {readyWallet ? (
              <InputAdornment position="end">
                <CircularProgress size={20} color="white" />
              </InputAdornment>
            ) : (
              "Descargar"
            )}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};
