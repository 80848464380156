import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logoThree from "../../assets/images/image2vector.svg";
import smart_qr from "../../assets/images/ticket/Grupo 463.png";
import {
  Typography,
  Box,
  Container,
  Backdrop,
  CircularProgress,
  Grid,
  Link,
} from "@material-ui/core";

import checkSvg from "../../assets/images/check2.png";
import bottle from "../../assets/images/ticket/bottle.svg";
import { useStyle } from "../virtual-ticket/viuw3.styles";
import { useSelector, useDispatch } from "react-redux";
import {
  createdDate,
  createdDateEsp,
  mapDate,
  mapDateEN,
} from "../../util/dateFormat";
import { SendDataHash } from "../../Axios/APILogin";
import {
  finishLoading,
  setError,
  startLoading,
} from "../../Redux/actions/uiError";
import { Alert, AlertTitle } from "@material-ui/lab";
import ReactGA from "react-ga";
// import qrcode from "qrcode";

// const opts = {
//   errorCorrectionLevel: "L",
//   margin: 1,
//   small: true,
// };

function StaticRoute() {
  const [passport, setPassport] = useState(null);
  const { loading, msgError } = useSelector((state) => state.uiError);
  const dispatch = useDispatch();
  const { hash } = useParams();
  const classes = useStyle();



  const doseNumber = (dose) => {
    if (dose === 1) return "era";
    if (dose === 2) return "da";
    if (dose === 3) return "era";
    if (dose === 4) return "ta";
  };

  const doseNumberEN = (dose) => {
    if (dose === 1) return "st";
    if (dose === 2) return "nd";
    if (dose === 3) return "rd";
    if (dose === 4) return "th";
  };

  useEffect(() => {
    if (process.env.REACT_APP_WORKSPACE === "production") {
      ReactGA.event({
        category: "PasaporteEscaneado",
        action: "PasaporteEscaneado",
      });
    }
  }, []);

  useEffect(() => {
    dispatch(startLoading());
    SendDataHash(hash)
      .then((data) => {
        dispatch(finishLoading());
        setPassport(data.data.data);
      })
      .catch((error) => {
        dispatch(finishLoading());
        const { message } = JSON.parse(error.request.response);
        dispatch(setError(message));
      });
  }, []);


  return (
    <div>
      {passport && (
        <Container>
          <Box className={classes.container}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                className={classes.ticket}
                bgcolor="white"
                borderRadius="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box width="100%" className={classes.box1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    alignItems="center"
                  >
                    <img
                      src={logoThree}
                      alt="ticket logo"
                      className={classes.logoImg}
                    />
                    <Typography className={classes.Title}>
                      CERTIFICADO DE VACUNACIÓN
                    </Typography>
                    <Typography className={classes.ticketTitle}>
                      VACCINATION CERTIFICATE
                    </Typography>
                  </Box>
                  <Box className={classes.box1_info}>
                    <Box className={classes.head_info}>
                      <Box className={classes.box1_info_each}>
                        <Box display="flex">
                          <Typography className={classes.box1_textOne}>
                            {" "}
                            FECHA DE EMISIÓN&nbsp;/&nbsp;
                          </Typography>
                          <Typography className={classes.box1_textTwo}>
                            {" "}
                            DATE OF ISSUE
                          </Typography>
                        </Box>
                        <Typography className={classes.box1_textThree}>
                          {createdDateEsp()}
                        </Typography>
                        <Typography className={classes.box1_textThreeEn}>
                          {createdDate()}
                        </Typography>
                      </Box>
                      <Box className={classes.box1_info_each2}>
                        <Box display="flex">
                          <Typography className={classes.box1_textOne}>
                            {" "}
                            PAÍS DE EMISIÓN&nbsp;/&nbsp;
                          </Typography>
                          <Typography className={classes.box1_textTwo}>
                            {" "}
                            ISSUING COUNTRY{" "}
                          </Typography>
                        </Box>
                        <Typography className={classes.box1_textThree}>
                          República Dominicana
                        </Typography>
                        <Typography className={classes.box1_textThreeEn}>
                          Dominican Republic
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className={classes.box2} borderRadius="10px" width="100%">
                  <Box className={classes.box2_info}>
                    <Box className={classes.box2_info_row}>
                      <Box className={classes.box2_info_each}>
                        <Box display="flex">
                          <Typography className={classes.textOne}>
                            {" "}
                            NOMBRE&nbsp;/&nbsp;
                          </Typography>
                          <Typography className={classes.textTwo}>
                            {" "}
                            NAME{" "}
                          </Typography>
                        </Box>
                        <Typography className={classes.textName}>
                          {passport?.name.toLowerCase()}
                        </Typography>
                      </Box>
                      <Box className={classes.box2_info_each2}>
                        <Box display="flex">
                          <Typography className={classes.textOne}>
                            {" "}
                            FECHA DE NACIMIENTO&nbsp;/&nbsp;
                          </Typography>
                          <Typography className={classes.textTwo}>
                            {" "}
                            DATE OF BIRTH{" "}
                          </Typography>
                        </Box>
                        <Typography className={classes.birthDate}>
                          {mapDate(passport.birthDate)}
                        </Typography>
                        <Typography className={classes.birthDateEn}>
                          {mapDateEN(passport.birthDate)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.box2_info_row}>
                      <Box className={classes.box2_info_each}>
                        <Box display="flex">
                          <Typography className={classes.textOne}>
                            {" "}
                            APELLIDO&nbsp;/&nbsp;
                          </Typography>
                          <Typography className={classes.textTwo}>
                            {" "}
                            LAST NAME{" "}
                          </Typography>
                        </Box>
                        <Typography className={classes.textName}>
                          {passport?.lastName.toLowerCase()}
                        </Typography>
                      </Box>

                      <Box className={classes.box2_info_each2}>
                        <Box display="flex">
                          <Typography className={classes.textOne}>
                            {" "}
                            NÚMERO DE IDENTIFICACIÓN&nbsp;/&nbsp;
                          </Typography>
                          <Typography className={classes.textTwo}>
                            {" "}
                            ID NUMBER{" "}
                          </Typography>
                        </Box>
                        <Typography className={classes.birthDate}>
                          {passport.cedula}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.box1_qr}>
                      
                    </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    alignItems="center"
                    className={classes.main_qr}
                  >
                    <Box className={classes.box_qr2}>
                      <Box className={classes.qr_bg}>
                        <img
                          className={classes.box_image_qr1}
                          src={checkSvg}
                          alt="checked"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.box3} borderRadius="10px" width="100%">
                  <Box className={classes.box3_details}>
                    {passport.vaccines
                      .sort((a, b) => a.dosis - b.dosis)
                      .map((vaccine, index) => (
                        <Box
                          key={index}
                          className={classes.vaccine_info}
                          mb="0.4rem"
                        >
                          <img
                            src={bottle}
                            alt="jeringa icono"
                            className={classes.vaccine_icon}
                          />
                          <Box className={classes.vaccine_info_box}>
                            <Box display="flex">
                              <Typography className={classes.detail_text}>
                                {vaccine.vacuna_administrada}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Typography className={classes.detail_text1}>
                                {vaccine.tipo_vacuna}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Typography className={classes.detail_text1}>
                                {vaccine.laboratorio}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Typography className={classes.detail_text1}>
                                {" "}
                                {vaccine.dosis}
                                <span className={classes.doseNumber}>
                                  {doseNumber(vaccine.dosis)}
                                </span>{" "}
                                dosis /&nbsp;{" "}
                              </Typography>
                              <Typography className={classes.detail_text2}>
                                {" "}
                                {vaccine.dosis}
                                <span className={classes.doseNumber}>
                                  {doseNumberEN(vaccine.dosis)}
                                </span>{" "}
                                dose
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Typography className={classes.detail_text1}>
                                {" "}
                                {mapDate(vaccine.fecha)} /&nbsp;{" "}
                              </Typography>
                              <Typography className={classes.detail_text2}>
                                {" "}
                                {mapDateEN(vaccine.fecha)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      )}

      {msgError && (
        <Grid>
          <Alert severity="info">
            <AlertTitle>No es posible verificar este código QR. Por favor vuelva a obtener{" "}
              <Link
                  href={"https://certificado.vacunate.gob.do/"}
                  color={"primary"}
                  underline="always"
                  target="_blank"
                  rel="noopener"
              >
                <strong>su certificado</strong>
              </Link>
            </AlertTitle>
          </Alert>
        </Grid>
      )}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size={100} />
      </Backdrop>
    </div>
  );
}

export default StaticRoute;
