import { makeStyles } from "@material-ui/core";
import "@fontsource/roboto";

export const useStyle = makeStyles((theme) => ({
  GridLink: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1rem 0 1rem"
  },
  link_1: {
    color: "#003876",
  },
  link_2: {
    color: "#003876",
  },
  link_text: {
    marginTop: "2rem",
  },
  container: {
    marginTop: "3rem",
    marginBottom: "4rem",
  },
  ticket: {
    maxWidth: "270px",
  },
  MsgError: {
    marginTop: "-8px",
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  box1: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #02245A",
    borderRadius: "12px 12px 0px 0px",
    opacity: "1",
    width: "350px",
    height: "204px",
    borderWidth: "1px 1px 10px 1px",
  },
  logoImg: {
    marginTop: "10px",
    width: 300,
    height: 70,
    marginBottom: "5px",
  },
  Title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#123363",
    letterSpacing: "1px"
  },
  ticketTitle: {
    fontStyle: "italic",
    fontSize: "18px",
    fontWeight: "400",
    color: "#123363",
    marginBottom: "10px",
    letterSpacing: "3px"
  },
  boxDate: {
    margin: "-0.5rem 0 0.5rem 0",
    textAlign: "center",
  },
  normalDateTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#123363",
  },
  dateTitle: {
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: 400,
    color: "#123363",
  },
  box2: {
    backgroundColor: "#02245A",
    width: "350px",
    display: "flex",
    height: "420px",
    flexDirection: "column",
    backgroundImage:
      "linear-gradient(to left, white 50%, rgba(255,255,255,0) 0%)",
    backgroundPosition: "top",
    backgroundSize: "50px 1px",
    backgroundRepeat: "repeat-x",
    position: "relative",
    "&::after": {
      content: '""',
      width: "20px",
      height: "20px",
      backgroundColor: "white",
      position: "absolute",
      borderRadius: "50%",
      top: "-10px",
      left: "-12px",
    },
    "&::before": {
      content: '""',
      width: "20px",
      height: "20px",
      backgroundColor: "white",
      position: "absolute",
      borderRadius: "50%",
      top: "-10px",
      right: "-12px",
    },
  },
  box2_info: {
    margin: "2rem 0 0rem 0rem",
    display: "flex",
    flexDirection: "column"
  },
  textOne: {
    color: "#ffffff",
    fontSize: "7px",
    fontWeight: "bold",
  },
  textTwo: {
    fontSize: "7px",
    fontStyle: "italic",
    color: "#d6d6d6",
  },
  box1_textOne: {
    color: "#00205b",
    fontSize: "7px",
    fontWeight: "bold",
  },
  box1_textTwo: {
    fontSize: "7px",
    fontStyle: "italic",
    color: "#00205b",
  },
  box1_textThree: {
    fontSize: "12px",
    color: "#00205b",
    fontWeight: "bold",
    marginTop: "1px"
  },
  box1_textThreeEn: {
    fontStyle: "italic",
    fontSize: "11px",
    color: "#00205b",
    marginTop: "-5px"
  },
  textName: {
    textTransform: "capitalize",
    fontSize: "12px",
    color: "#fff",
    fontWeight: "bold",
  },
  birthDate: {
    fontSize: "12px",
    color: "#fff",
    fontWeight: "bold",
  },
  birthDateEn: {
    fontStyle: "italic",
    fontSize: "11px",
    color: "#d6d6d6",
  },
  qr_bg: {
    cursor: "pointer",
  },
  box_qr:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1rem"
  },
  box1_qr:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box_qr2:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "1.5rem"
  },
  image_qr:{
    width: "125PX",
    marginTop: "0.5rem"
  },
  image_qr2:{
    marginRight: "7px"
  },
  text_qr:{
    color: "white",
    fontSize: "8px"
  },
  modalQr_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  main_qr: {
    marginRight: "10px"
  },
  box3: {
    backgroundColor: "#02245A",
    width: "350px",
    height: "390px",
    backgroundImage:
      "linear-gradient(to left, white 50%, rgba(255,255,255,0) 0%)",
    backgroundPosition: "top",
    backgroundSize: "50px 1px",
    backgroundRepeat: "repeat-x",
    position: "relative",
    "&::after": {
      content: '""',
      width: "20px",
      height: "20px",
      backgroundColor: "white",
      position: "absolute",
      borderRadius: "50%",
      top: "-10px",
      left: "-12px",
    },
    "&::before": {
      content: '""',
      width: "20px",
      height: "20px",
      backgroundColor: "white",
      position: "absolute",
      borderRadius: "50%",
      top: "-10px",
      right: "-12px",
    },
  },
  text_detail: {
    lineHeight: "25px",
    color: "#ffffff",
    fontSize: "9.5px",
    fontWeight: "700",
    marginTop: "-5px",
  },
  text_detail_english: {
    color: "#ffffff",
    lineHeight: "25px",
    fontSize: "9.5px",
    fontStyle: "italic",
    marginTop: "-5px",
  },
  box3_details: {
    margin: "1rem 0 0 1.5rem",
    display: "flex",
    flexDirection: "column",
  },
  vaccine_info: {
    display: "flex",
    alignItems: "center",
  },
  vaccine_icon: {
    marginTop: "5px",
    height: "40px",
    color: "white",
    marginRight: "0.5rem"
  },
  vaccine_info_box: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "15px",
    marginTop: "0.5rem"
  },
  detail_text: {
    color: "white",
    fontSize: "13px",
    fontWeight: "bold",
  },
  detail_text1: {
    color: "#d6d6d6",
    fontSize: "9px",
    fontWeight: "500",
  },
  detail_text2: {
    color: "#d6d6d6",
    fontSize: "9px",
    fontWeight: "500",
    fontStyle: "italic",
  },
  doseNumber: {
    fontSize: "7px",
    verticalAlign: "top",
  },
  cardGrid: {
    width: "350px",
    padding: "0",
  },
  cardContent: {
    flexGrow: 1,
  },

  button: {
    marginTop: "10px",
    marginLeft: "10px",
    height: "60px",
    width: "6em",
  },

  button2: {
    margin: "10px 3px 0 3px",
    height: "45px",
    width: "50%",
    textTransform: "none",
    fontSize: "16px",
  },

  button3: {
    margin: "5px 3px 0 3px",
    height: "45px",
    width: "100%",
    textTransform: "none",
  },
  info_wallet: {
    color: "#000000",
    fontSize: "15px",
    padding: "1.5rem 0 0.5rem 0.5rem",
    fontWeight: "500",
  },
  info_chrome: {
    color: "#000000",
    fontSize: "15px",
    padding: "1.5rem 0 0.5rem 0.5rem",
    fontWeight: "500",
  },
  icons: {
    width: "18px",
  },
  icons2: {
    width: "70px",
  },
  imgB: {
    marginRight: "10px",
    width: "70px",
    "@media screen and (max-width: 360px)": {
      width: "80px",
    },
  },

  buttomB: {
    width: "100%",
  },

  IconGrande: {
    paddingRight: "15px",
    transform: "scale(1.5)",
    transformOrigin: "5px 10px",
  },
  send_support: {
    position: "fixed",
    width: "70%",
    maxWidth: "500px",
    top: "10px",
    left: "15%",
    "@media screen and (min-width: 714px)": {
      left: "calc(50% - 250px)",
    },
    zIndex: "2000",
  },

  // static route style******
  check_box: {
    display: "flex",
    justifyContent: "center",
  },
  check_static: {
    width: "95px",
  },

  // modal send by email styles********
  content: {
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: "0.5rem",
    border: "2px solid #00205b",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: "200px",
    maxWidth: "450px",
    "@media screen and (max-width: 500px)": {
      width: "auto",
    },
    "@media screen and (max-width: 455px)": {
      margin: "1rem 1rem",
    },
  },
  send_title: {
    color: "#00205b",
    fontWeight: "600",
  },
  select: {
    width: "100%",
    margin: "1rem 0",
    color: "#00205b",
  },
  modal_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  positionCard: {
    minHeight: "240px",
    position: "sticky",
    top: "calc(50% - 200px)",
    left: "calc(50% - 300px)",
  },
  buttons_content: {
    width: "90%",
    "@media screen and (max-width: 350px)": {
      flexDirection: "column",
    },
  },
  modalButton1: {
    textTransform: "none",
    minWidth: "130px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#00205b",
    "@media screen and (max-width: 470px)": {
      margin: "0 0 1rem 0",
    },
  },
  modalButton2: {
    textTransform: "none",
    minWidth: "130px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#fff",
    "@media screen and (max-width: 470px)": {
      margin: "0 0 1rem 0",
    },
    color: "#00205b",
    border: "2px solid #00205b",
  },
  buttons_row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  button_smart: {
    color: "white",
    margin: "5px 3px 0 3px",
    height: "45px",
    width: "100%",
    backgroundColor: "#00205b",
    "&:hover": {
      color: "white",
      backgroundColor: "#00205b",
    },
  },
  smarth_icon: {
    width: "30px",
  },
  display_none: {
    display: "none",
  },
  navigator_icon: {
    width: "25px",
    position: "relative",
    top: "7px",
  },
  textButton: {
    marginLeft: "5px",
    fontWeight: "500",
    color: "#000",
  },
  textButtonSHC: {
    marginLeft: "5px",
    fontWeight: "500",
    color: "white",
  },
  box_image_qr1:{
    width: "220px", 
    height: "220px"
  },
  box1_image_qr1:{
    width: "95px", 
    height: "95px"
  },
  box_image_qr2:{
    width: "300px", 
    height: "300px"
  },
  box2_info_row:{
    display: "flex",
    width: "95%",
    justifyContent: "space-between",
    maxHeight: "65px",
    marginLeft: "1rem"
  },
  box2_info_each:{
    margin: "0 0 2rem 0",
    width: "43%",
  },
  box2_info_each2:{
    margin: "0 0 2rem 0",
    width: "52%",
  },
  box1_info_each:{
    marginBottom: "20px"
  },
  box1_info_each2:{
    
  },
  box1_info:{
    margin: "0 1rem",
    display: "flex",
    justifyContent: "space-between"
  },
  disabled_button:{
    display: "none"
  },
  head_info:{
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  }
}));
