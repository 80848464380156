import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal_container: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: "0.5rem",
    border: "2px solid #00205b",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: "200px",
    maxWidth: "450px",
    "@media screen and (max-width: 500px)": {
      width: "auto",
    },
    "@media screen and (max-width: 455px)": {
      margin: "1rem 1rem",
    },
  },
  select: {
    width: "100%",
    margin: "1rem 0",
    color: '#00205b',
  },
  send_title:{
    color: "#00205b",
    fontWeight: "600",
  },
  MsgError: {
    marginTop: "-8px",
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  buttons_content: {
    width: "90%",
    "@media screen and (max-width: 350px)": {
      flexDirection: "column",
    },
  },
  modalButton2: {
    textTransform: 'none',
    minWidth: "130px",
    height: '45px',
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#fff",
    "@media screen and (max-width: 470px)": {
      margin: "0 0 1rem 0",
    },
    color: "#00205b",
    border: "2px solid #00205b",
  },
  modalButton1: {
    textTransform: 'none',
    minWidth: "130px",
    height: '45px',
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#00205b",
    "@media screen and (max-width: 470px)": {
      margin: "0 0 1rem 0",
    },
  },
  form:{
    padding: "1.5rem 0 0.5rem 0.5rem"
  },
  gridError:{
    marginTop: "-15px"
  },
}));
