import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    textAlign: "center",
    color: 'white',
    padding: '36px',
    "@media screen and (max-width: 412px)": {
      paddingLeft: '10px',
      paddingRight: '10px',
    }
  },
  logo: {
    width: '100%',
    padding: "3rem 0 1rem 0",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "0.3rem",
    color: "#00205b"
  },
  paragraph: {
    margin: "auto",
    width: '100%',
    color:"#00205b",
    "@media screen and (max-width: 412px)": {
      padding: '0',
    }
  },
  title_term:{
    fontWeight: '500',
    fontSize: "20px",
    color:"#00205b"

  },
  subtitle_term:{
    fontSize: "18px",
    fontWeight: "500",
    marginTop: "1.5rem",
    marginBottom: "0.5rem",
    color:"#00205b"
  },
  wrap:{
    display: 'inline-block',
    wordWrap: 'break-word',
    wordBreak: 'normal',
    lineBreak: 'strict',
    "@media screen and (max-width: 350px)": {
      maxWidth: '80vw',
      margin: '0'
    },
  },
  checkbox: {
    alignItems: "center",
    width: '90%',
    margin: 'auto',
    marginBottom: '10px',
    color: '#00205b',
  },
  check: {
    color: '#00205b',
  },
  boxButton: {
    "@media screen and (max-width: 490px)": {
      flexDirection: "column",
      width: '90%',
      margin: 'auto',
    },
  },
  link: {
    textDecoration: "none",
  },
  button: {
    textTransform: 'none',
    minWidth: "175px",
    height: '45px',
    fontSize: "18px",
    fontWeight: "bold",
    color: '#00205b',
    border: '2.5px solid #00205b',
    "@media screen and (max-width: 490px)": {
      width: "100%",
    }
  },
  active: {
    color: 'white',
    marginBottom: '3rem',
    minWidth: "175px",
    height: '45px',
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: '#00205b',
    textTransform: "none",
    "@media screen and (max-width: 490px)": {
      width: "100%",
      marginTop: "1rem"
    },
    "&:hover":{
      backgroundColor: "#00207b",
    }
  },
  desactive: {
    textTransform: 'none',
    marginBottom: '3rem',
    minWidth: "175px",
    height: '45px',
    fontSize: "18px",
    fontWeight: "bold",
    "@media screen and (max-width: 490px)": {
      width: "100%",
      marginTop: "1rem"
    }
  },
}));
