import axios from "axios";
const apiKey = process.env.REACT_APP_X_API_KEY;

export const acceptConsents = async (code, token) => {
  try {
    return await axios.put(`${process.env.REACT_APP_API_URL}/consents`, {
      fecha_consentimiento: new Date(),
    },{
      headers: { 
        Authorization: "Bearer " + token,
        "X-API-KEY": apiKey
      },
    });
  } catch (error) {
    throw error;
  }
};
