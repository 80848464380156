import { TYPES } from "../../types/types";

const setError = (message, statusCode) => ({
  type: TYPES.UISetError,
  payload: {
    message,
    statusCode
  }
});

const removeError = () => ({
  type: TYPES.UIRemoveError,
});

const startLoading = () => ({
  type: TYPES.uiStartLoading,
});

const finishLoading = () => ({
  type: TYPES.uiFinishLoading,
});

const startLoading2 = () => ({
  type: TYPES.uiStartLoading2,
});

const finishLoading2 = () => ({
  type: TYPES.uiFinishLoading2,
});

const error500 = () => ({
  type: TYPES.uiError500,
});

const removeError500 = () => ({
  type: TYPES.removeError500,
});

export { setError, removeError, startLoading, finishLoading, startLoading2, finishLoading2, error500, removeError500 };
