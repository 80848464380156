import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  main: {
    padding: "36px",
    "@media screen and (max-width: 412px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  logo: {
    width: "100%",
    height: "",
    padding: "3rem 0 1rem 0",
  },
  text: {
    color: "#00205b",
    textAlign: "center",
    fontWeight: "bold",
    margin: "1rem 0",
    "@media screen and (max-width: 495px)": {
      fontSize: "20px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "10px",
    },
  },
  vaccine_icon: {
    height: "35px",
    marginTop:"-60px",
    color: "white",
    marginRight: "5%",
    "@media screen and (max-width: 495px)": {
      height: "25px",
    },
    "@media screen and (max-width: 430px)": {
      height: "23px",
      marginRight: "3%",
    },
    "@media screen and (max-width: 320px)": {
      height: "20px",
    },
  },
  BoxVaccuna: {
    width: "100%",
    "@media screen and (max-width: 430px)": {
      width: "90%",
    },
  },
  textOne: {
    float: "left",
    lineHeight: "25px",
    color: "#00205b",
    fontSize: "14px",
    "@media screen and (max-width: 495px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "10px",
    },
  },
  textTwo: {
    float: "right",
    color: "#00205b",
    lineHeight: "25px",
    left: "150px",
    fontSize: "14px",
    fontStyle: "italic",
    marginLeft: "2px",
    "@media screen and (max-width: 495px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "10px",
    },
  },
  textDosis: {
    color: "#00205b",
    fontSize: "17px",
    fontWeight:"bold",
    lineHeight: "25px",
    "@media screen and (max-width: 495px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "10px",
    },
  },

  textDosis1: {
    color: "#00205b",
    fontSize: "17px",
    lineHeight: "25px",
    "@media screen and (max-width: 495px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "10px",
    },
  },

  doseNumber:{
    fontSize: '10px',
    verticalAlign: '6px',

  },

  boxSoporte: {
    marginTop: "10%",
    marginBottom: "10%",
    textAlign: "center",
  },
  textInfo: {
    color: "#00205b",
    fontSize: "20px",
    "@media screen and (max-width: 495px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "10px",
    },
  },
  textModal: {
    color: "#00205b",
    fontSize: "20px",
    textDecoration: "none",
    borderBottom: "1px solid",
    marginLeft: "8px",
    "@media screen and (max-width: 495px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 320px)": {
      fontSize: "10px",
    },
  },
  box_buttons: {
    display: "flex",
    margin: "2rem 0 1rem 0",
    "@media screen and (max-width: 490px)": {
      flexDirection: "column",
      margin: "5rem 0 1rem 0",
    },
  },
  link: {
    textDecoration: "none",
  },
  btn_back: {
    textTransform: "none",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#00205b",
    border: "2.5px solid #00205b",
    "@media screen and (max-width: 490px)": {
      width: "100%",
    },
  },
  btn_next: {
    color: "#ffffff",
    minWidth: "175px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    backgroundColor: "#00205b",
    textTransform: "none",
    "@media screen and (max-width: 490px)": {
      width: "100%",
      marginTop: "1rem",
    },
    "&:hover": {
      backgroundColor: "#00207b",
    },
  },
  support_modal: {
    backgroundColor: "rgba(0,0,0,0)",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "scroll",
  },
  modalEnviar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxModalEnviar: {
    margin: "1rem",
    width: "500px",
    minHeight: "200px",
    backgroundColor: "white",
    color: "white",
    borderRadius: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #00205b",
  },
  modal_content: {
    width: "80%",
    margin: "1rem",
  },
  modal_text: {
    fontWeight: "bold",
    color: "#00205b",
  },
  modal_text_error: {
    fontWeight: "bold",
    color: theme.palette.error.light,
  },
  modal_box_button: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    marginTop: "1.5rem",
  },
  modal_button: {
    textTransform: "none",
    color: "white",
    backgroundColor: "#00205b",
    borderRadius: "0.5rem",
    fontWeight: "bold",
    minWidth: "150px",
    height: "45px",
    fontSize: "18px",
    "@media screen and (max-width: 470px)": {
      width: "100%",
      margin: "1rem 0",
    },
    "&:hover": {
      backgroundColor: "#00207b",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFF",
  },
  gridError:{
    marginTop: "20px"
  },
}));
