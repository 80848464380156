import { TYPES } from "../../types/types";
import { error500, finishLoading, setError, startLoading } from "./uiError";
import { SendDataVacunnate } from "../../Axios/APILogin";
import { checkOpenTickets } from "../../Axios/checkOpenTickets";




export const AuthRequestVACCINATEDPassport = (
  code,
) => {
  return (dispatch) => {
    return new Promise((response, reject) => {
      dispatch(startLoading());
  
      SendDataVacunnate(code)
        .then((data) => {
          dispatch(finishLoading());
          const passport = data.data.data;
          passport.code = code;
          const status = data.data.statusCode;
          const token = data.data.token
          dispatch(AuthGuardarPasaporte(passport, status, token));
          response(true)
        })
        .catch((error) => {
          if(error.request.response){
            const { message } = JSON.parse(error.request.response);
            const { statusCode } = JSON.parse(error.request.response);
            const {cedula}= JSON.parse(error.request.response);
            const {nombre}= JSON.parse(error.request.response);
            const {token}=JSON.parse(error.request.response);

            if(statusCode === 401){
              checkOpenTickets(token, cedula)
              .then(res => {
                if(res.open > 0){
                  dispatch(finishLoading());
                  dispatch(openTickets(res.open))
                  response(false)
                }else{
                  dispatch(GuardarsDatos(nombre,cedula,token));
                  dispatch(setError(message, statusCode));
                  dispatch(finishLoading());
                  response(false)
                }
              })
            }else if(statusCode === 400){
              dispatch(setError(message, statusCode));
              dispatch(finishLoading());
              response(false)
            }else if(statusCode === 500){
              dispatch(finishLoading());
              dispatch(error500());
              response(false)
            }else{
              dispatch(setError("",statusCode));
              dispatch(finishLoading());
              response(false)
            }
          }else{
            dispatch(setError("Error al hacer la petición", 400));
            dispatch(finishLoading());
            response(false)
          }
        });
    })
  };
};

export const AuthBorrarPasaporte = () => ({
  type: TYPES.logout
});


export const AuthGuardarPasaporte = (passport, status,token) => ({
  type: TYPES.login,
  payload: {
    passport,
    status,
      token
  },
});


export const GuardarsDatos =(nombre, cedula,token)=>({

type: TYPES.Salve,
    payload: {
        nombre,
        cedula,
        token
    },
});

export const openTickets =(openTickets)=>({

  type: TYPES.openTickets,
      payload: {
        openTickets
      },
  });
