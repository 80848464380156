import axios from "axios";
import { error500 } from "../Redux/actions/uiError";
const apiKey = process.env.REACT_APP_X_API_KEY;

function GetDataPdf(token, dispatch) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/pdf-generator`, {
        headers: { 
          Authorization: "Bearer " + token,
          "X-API-KEY": apiKey
        },
        responseType: "blob",
      })
      .then(function (response) {
        console.log(response)
        let data = window.URL.createObjectURL(response.data);
        let filename = response.request.getResponseHeader("Content-Disposition");
        let removeFilename = filename.split("=")[1]
        var a = document.createElement("A");
        a.setAttribute("download", removeFilename);
        a.setAttribute("Authorization", token);
        a.setAttribute("href", data);
        a.click();
        resolve(response)
      })
      .catch(function (error) {
        if(error.response.status === 500){
          dispatch(error500())
        }
        error.response.data.text().then((e) => {
          reject(JSON.parse(e))
        })
      });
  })
}

export { GetDataPdf };
