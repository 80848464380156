import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { React, useEffect } from "react";
import { Layout } from "../components/layout/Layout";
import Login from "../components/AuthLogin/Login";
import View2 from "../components/view2/View2";
import VirtualTicket from "../components/virtual-ticket/view3";
import ViewPasaporte from "../components/view3/ViewPasaporte";
import StaticRoute from "../components/StaticRoute/StaticRoute";
import Asistencia from "../components/asistencia/Asistencia";
import AsisteRoute from "./AsisteRoute";
import PrivateRoute from "./PrivateRoute";
import useStrapi from "../hooks/useStrapi";
import { useDispatch } from "react-redux";
import {
  getStrapiData,
  getStrapiError,
} from "../Redux/actions/actionStrapiData";
import Maintenance from "../components/mantenimiento";
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from './routes.styles'
import vacunateLogo from '../assets/images/vacunaterd.svg'

function AppRoutes() {
  const dispatch = useDispatch();
  const classes = useStyles()
  const [strapiData, strapiError] = useStrapi("/certificado-de-vacunacion-app");

  useEffect(() => {
    if (strapiError) {
      dispatch(getStrapiError(strapiError));
    } else {
      dispatch(getStrapiData(strapiData));
    }
  }, [strapiData, strapiError]);

  return (
    <BrowserRouter>
      {
        strapiData ?
        <>
          {
            strapiData?.Mantenimiento ?
              <>
                <Redirect to="/Mantenimiento" />
                <Switch>
                  <Route
                    path="/Mantenimiento"
                    component={Maintenance}
                    exact
                  />
                </Switch>
              </>
            :
              <Layout>
                <Switch>
                  <PrivateRoute path={"/terminos"} component={View2} exact />
                  <PrivateRoute path={"/vacunas"} component={ViewPasaporte} exact />
                  <PrivateRoute path={"/certificado"} component={VirtualTicket} exact />

                  <Route path={"/"} component={Login} exact />
                  <AsisteRoute path={"/asistencia"} component={Asistencia} exact />
                  <Route path="/staticPassport/:hash" component={StaticRoute} exact />
                  <Redirect to="/" />
                </Switch>
              </Layout>
          }
        </>
        :
        <Box className={classes.box_strapi_loading} > 
          <CircularProgress size={100} color="inherit" className={classes.strapi_loading} />
          <img className={classes.vacunate_logo} src={vacunateLogo} alt="VacunateRd Logo" />
        </Box>
      }
    </BrowserRouter>
  );
}

export default AppRoutes;
