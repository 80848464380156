import { React, useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Container,
  FormControl,
  CircularProgress,
  Backdrop,
  Box,
  Link,
  Modal,
} from "@material-ui/core";
import { useStyle } from "./style";
import logoThree from "../../assets/images/tres-logos-fn-azul-short2.svg";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "validator/es";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthBorrarPasaporte,
  AuthRequestVACCINATEDPassport,
} from "../../Redux/actions/actionPassport";
import { Redirect } from "react-router-dom";
import {removeError, removeMessageError} from "../../Redux/actions/uiError";
import "date-fns";
import { Alert, AlertTitle } from "@material-ui/lab";
import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

function Login() {
  const codigoRef = useRef();
  const captcha = useRef(null);
  const clases = useStyle();
  const [Error, setError] = useState(null);
  const [Error1, setError1] = useState(null);
  const dispatch = useDispatch();
  const { statusPassport } = useSelector((state) => state.StatePassport);
  const { open } = useSelector((state) => state.StatePassport);
  const { loading, loading2,  msgError, status } = useSelector((state) => state.uiError);
  const [openModal, setOpenModal] = useState(false);
  const CaptchaKey = process.env.REACT_APP_CAPTCHA_KEY;
  const [ErrorMesage , setErrorMesage] = useState("");
  const { strapiData, strapiError } = useSelector((state) => state.strapiData);
  const view1 = strapiData?.vista1;

  //first content
  const firstIndex = view1?.contenido.indexOf('{');
  const secondIndex = view1?.contenido.indexOf('}');

  let content = {};

  if (firstIndex === -1) {
    content = {
      firstPart:  view1?.contenido.substring(0, secondIndex),
      secondPart: view1?.contenido.substring(secondIndex, view1.contenido.length),
      link: ''
    }
  } else if(secondIndex === -1) {
    content = {
      firstPart:  view1?.contenido.substring(0, firstIndex),
      secondPart: view1?.contenido.substring(firstIndex, view1.contenido.length),
      link: ''
    }
  } else {
    content = {
      firstPart:  view1?.contenido.substring(0, firstIndex),
      secondPart: view1?.contenido.substring(secondIndex+1, view1.contenido.length),
      link: view1?.contenido.substring(firstIndex+1, secondIndex)
    }
  }

  
  //solicitud en curso mensaje
  const soliFirstIndex = strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.indexOf('{');
  const soliSecondIndex = strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.indexOf('}');

  let solicitudEnCurso = {};
  let solicitudEnCursoMensage = ''; 

  if(soliFirstIndex === -1) {
    solicitudEnCurso = {
      firstPart:  strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(0, soliSecondIndex),
      secondPart: strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(soliSecondIndex, strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.length)
    }
    solicitudEnCursoMensage = `${solicitudEnCurso.firstPart}${solicitudEnCurso.secondPart}`;
  } else if(soliSecondIndex === -1) {
    solicitudEnCurso = {
      firstPart:  strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(0, soliFirstIndex),
      secondPart: strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(soliFirstIndex, strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.length)
    }
    solicitudEnCursoMensage = `${solicitudEnCurso.firstPart}${solicitudEnCurso.secondPart}`;

  } else {
    solicitudEnCurso = {
      firstPart:  strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(0, soliFirstIndex),
      secondPart: strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(soliSecondIndex+1, strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.length)
    }
    solicitudEnCursoMensage = `${solicitudEnCurso.firstPart} ${open} ${solicitudEnCurso.secondPart}`;
  }
  
  // console.log(status);
  const eliminarErroresRedux = () => {
   /* setTimeout(() => {
      dispatch(removeMessageError());
    }, 20000);*/
    //setErrorMesage(ErrorMesage);
  };

  const IsValidator = () => {
    if (!captcha.current.getValue()) {
      setError("Debe validar que no es un robot");
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  useEffect(() => {
    if (open) {
      setOpenModal(true);
    }
  }, [open]);

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(AuthBorrarPasaporte());
  };

  const IsValidator1 = () => {
    const CodigoValue = codigoRef.current.value;
    if (validator.isEmpty(CodigoValue)) {
      dispatch(removeError());
      setError1("Este campo es requerido");
      return false;
    }

    if (validator.isLength(CodigoValue, 11)) {
      dispatch(removeError());
      setError1("El código debe tener un máximo de 10 caracteres");
      return false;
    } else {
      setError1(null);
      status===400 && dispatch(removeError());
      return true;
    }
  };
  const HandlePassportVacunatte = () => {
    if (IsValidator() & IsValidator1()) {
      dispatch(AuthRequestVACCINATEDPassport(codigoRef.current.value));
    }
  };

  return (
    <Container className={clases.container} fixed>
      <Grid align="center" container>
        <Grid item xs={12}>
          <Box>
            <img src={logoThree} alt="VacunateRD" className={clases.logo} />
          </Box>
          <Typography variant="h5" className={clases.logintitle}>
            Certificado de Vacunación
          </Typography>
        </Grid>

        <Grid align="left" item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={clases.label}>
              Código de validación *
            </Typography>
            <TextField
              required
              variant="outlined"
              margin="normal"
              fullWidth
              id="code"
              name="code"
              inputRef={codigoRef}
              autoComplete="Código Secreto de Salud"
              onBlur={IsValidator1}
              onKeyUp={IsValidator1}
              inputProps={{
                maxLength: 10,
                className: clases.input,
              }}
              InputProps={{
                className: clases.input,
              }}
            />

            {Error1 && (
              <Grid className={clases.gridError}>
                <Typography className={clases.MsgError}>{Error1}</Typography>
              </Grid>
            )}

            {status === 400 && (
              <Grid className={clases.gridError}>
                <Typography className={clases.MsgError}>{msgError}</Typography>
              </Grid>
            )}

            {status === 429 && (
              <Grid className={clases.gridError}>
                <Typography className={clases.MsgError}>
                  Demasiadas peticiones en un corto periodo de tiempo. Espere 1
                  minuto y vuelva a intentarlo.
                </Typography>
              </Grid>
            )}

            {status === 400 || status ===429 ? eliminarErroresRedux() : ""}

            <Grid item xs={12} sm={12} className={clases.captcha}>
              <ReCAPTCHA
                ref={captcha}
                sitekey={CaptchaKey}
                onChange={IsValidator}
                hl="es"
                className={clases.Recaptcha}
              />
            </Grid>

            {Error && (
              <Grid className={clases.gridError2}>
                <Typography className={clases.MsgError}>{Error}</Typography>
              </Grid>
            )}

            <Grid className={clases.gridMessage}>
              <Alert severity={strapiError ? "error": "info"}>
                <AlertTitle >
                  {
                    loading2 ?
                    <>
                      <span className={clases.alert_text1} >.</span>
                      <span className={clases.alert_text2} >.</span>
                      <span className={clases.alert_text3} >.</span>
                      <span className={clases.alert_text4} >.</span>
                      <span className={clases.alert_text5} >.</span>
                    </>
                    :
                    <>
                      {
                        strapiError
                        ??
                        <>
                          {`${content.firstPart} `}
                          <Link
                            href={
                              process.env.REACT_APP_WORKSPACE === "production" 
                              ? "https://solicitud.vacunate.gob.do" 
                              : "https://qa-tarjetadigital2-dot-vacunatedo.ue.r.appspot.com"
                            }
                            color={"primary"}
                            underline="always"
                            target="_blank"
                            rel="noopener"
                          >
                            <strong>{content.link}</strong>
                          </Link>{" "}
                          {` ${content.secondPart}`}
                      
                        </>
                      }
                    </>
                  }
                </AlertTitle>
              </Alert>
            </Grid>
            {statusPassport === 201 && <Redirect to="/terminos" />}

            {statusPassport === 200 && <Redirect to="/vacunas" />}

            {status === 401 && <Redirect to="/asistencia" />}

            <Grid item className={clases.buttons_container}>
              <Button
                onClick={HandlePassportVacunatte}
                type="submit"
                disabled={loading}
                variant="contained"
                color="inherit"
                textTransform="none"
                className={clases.boton}
              >
                Continuar
              </Button>
            </Grid>
            <Backdrop className={clases.backdrop} open={loading}>
              <CircularProgress size={100} color="inherit" />
            </Backdrop>
          </FormControl>
        </Grid>
      </Grid>
      <Modal className={clases.modalEnviar} open={openModal}>
        <Box className={clases.boxModalEnviar}>
          <Box className={clases.modal_content}>
            <Typography align="justify" className={clases.modal_text}>
              {
                strapiError ?
                "En este momento no le es posible crear un ticket de asistencia, ya que cuenta con uno o varios tickets pendientes todavía."
                :
                  <ReactMarkDown 
                    children={solicitudEnCursoMensage}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}
                  />
              }
            </Typography>
            <Box className={clases.modal_box_button}>
              <Button
                onClick={handleCloseModal}
                className={clases.modal_button}
              >
                Ok, entendido
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
}

export default Login;
