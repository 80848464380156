const monthNames = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

const monthNamesIS = [
  "January",
   "February",
   "March",
   "April",
   "May",
   "June",
   "July",
   "August",
   "September",
   "October",
   "November",
   "December",
];

export const mapDate = (date) => {
  let [year, month, day] = date.split("-")

  return `${day} de ${monthNames[parseInt(month) - 1]} de ${year}`
}

export const mapDateEN = (date) => {
  let [year, month, day] = date.split("-")
  return `${monthNamesIS[parseInt(month) - 1]} ${day<10 ? day.replace(0,""): day }, ${year}`
}

export const createdDateEsp = () => {
  let today = new Date();

  let day = today.getDate();
  let month = monthNames[today.getMonth()];
  let year = today.getFullYear();
  return `${day<10 ? '0':""}${day} de ${month} de ${year}`;
};

export const createdDate = () => {
  let today = new Date();
  let day = today.getDate();
  let month = monthNamesIS[today.getMonth()];
  let year = today.getFullYear();
  return `${month} ${day}, ${year}`;
};


export const createdC = () => {
    let today = new Date();

    let day = today.getDate();
    let month = today.getMonth()+1;
    let year = today.getFullYear();
    return `${day<10 ? '0':""}${day}-${month}-${year}`;
};