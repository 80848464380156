import axios from "axios";
const apiKey = process.env.REACT_APP_X_API_KEY;

async function sendPDF(correo, pdf, tokenP) {
  try {
    let hoy = new Date()
    const formData = new FormData();
    const file = blobToFile(pdf, `Certificado-${hoy.getDate()}_${hoy.getMonth()+1}_${hoy.getFullYear()}.pdf`)

    formData.append("to", correo);
    formData.append("files", file);

    return await axios.post(
      `${process.env.REACT_APP_API_URL}/email/personalInbox`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + tokenP,
          "X-API-KEY": apiKey,
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

function blobToFile(theBlob, fileName){
  const file = new File([theBlob], fileName, {
    type: "application/pdf"
  })
  return file
}

export { sendPDF };
