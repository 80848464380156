import React from 'react';
import {useSelector} from "react-redux";
import {Route, Redirect} from "react-router-dom";

function AsisteRoute({component: Component, ...rest}) {
    const {  msgError } = useSelector((state) => state.uiError);

    return (
        <div>
            <Route{...rest}>
                {msgError?
                    <Component/>
                    : <Redirect to={"/"}/>
                }
            </Route>
        </div>
    );
}
export default AsisteRoute;