import { React, useCallback, useEffect, useRef, useState } from "react";
import logoThree from "../../assets/images/Banner1.svg";
import walletpasses_icon from "../../assets/images/walletpasses_icon.png";
import Apple_wallet_icon from "../../assets/images/Apple_wallet_icon.svg";
import pdf_icon from "../../assets/images/pdf.svg";
import jpg_icon from "../../assets/images/jpg.svg";
import email_icon from "../../assets/images/email_icon.svg";
import qrcode from "qrcode";

import {
  Typography,
  Box,
  Modal,
  Container,
  Grid,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import bottle from "../../assets/images/ticket/bottle.svg";
import { useStyle } from "./viuw3.styles";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  createdDate,
  createdDateEsp,
  mapDate,
  mapDateEN,
} from "../../util/dateFormat";
import { AuthBorrarPasaporte } from "../../Redux/actions/actionPassport";
import { Alert, AlertTitle } from "@material-ui/lab";
import UseModal from "../../hooks/useModal";
import ReactGA from "react-ga";
import { Getfilepkpass } from "../../Axios/getPkpass";
import { useForm } from "../../hooks/useForm";
import { getPdf } from "../../Axios/getPDF";
import { sendPDF } from "../../Axios/postPdf";
import { GetDataPdf } from "../../Axios/APIpdf";
import { SendEmailModal } from "../SendEmailModal/SendEmailModal";
import { WalletAndroidModal } from "../WalletIosModal/WalletAndroidModal";
import { getJpg } from "../../Axios/getJpg";
import { error500 } from "../../Redux/actions/uiError";

const opts = {
  errorCorrectionLevel: "M",
  margin: 2,
  small: true,
};

function VirtualTicket() {
  const initialState = {
    correo: "",
  };
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [, modalExit] = UseModal();
  const [emailWasSended, setEmailWasSended] = useState(false);
  const [emailWasNotSended, setEmailWasNotSended] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const { token, passport } = useSelector((state) => state.StatePassport);
  const [ReadyPDF, setReadyPDF] = useState(false);
  const [ReadyJpg, setReadyJpg] = useState(false);
  const regEmailExp = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
  const [validCorreo, setValidCorreo] = useState(true);
  const [errorCorreo, setErrorCorreo] = useState("");
  const [readyWallet, setReadyWallet] = useState(false);
  const [OpenSendEmail, setOpenSendEmail] = useState(false);
  const [Value, handleInputChange, , reset] = useForm(initialState);
  const [dataPdf, setDataPdf] = useState(null);
  const { correo } = Value;
  const [isIos, setIsIos] = useState(false);
  const [modalWallet, setModalWallet] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/iphone|ipad|mac os/.test(userAgent)) {
      setIsIos(true);
    }
  }, []);

  const [qr1, setQr1] = useState(null);

  const qrValue = useCallback(() => {
    return `${window.location.origin}${passport.staticPassportUrl}`;
  },[passport.staticPassportUrl])
  

  useEffect(() => {
    qrcode.toDataURL(qrValue(), opts, function (error, url) {
      setQr1(url);
    });
  }, [qrValue]);

  const doseNumber = (dose) => {
    if (dose === 1) return "era";
    if (dose === 2) return "da";
    if (dose === 3) return "era";
    if (dose === 4) return "ta";
  };

  const doseNumberEN = (dose) => {
    if (dose === 1) return "st";
    if (dose === 2) return "nd";
    if (dose === 3) return "rd";
    if (dose === 4) return "th";
  };

  useEffect(() => {
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        setOpenSendEmail(false);
      }
    });
  }, []);

  const AddToWallet = () => {
    setReadyWallet(true);
    Getfilepkpass(token).then(() => {
      setReadyWallet(false);
    })
    .catch(err => {
      setReadyWallet(false);
      if(err.response.status === 500){
        dispatch(error500())
      }
      if(err.response.data.message === "jwt expired"){
        dispatch(AuthBorrarPasaporte())
      }
    });
  };

  const PDF = (token) => {
    getPdf(token).then((res) => {
      setDataPdf(res);
    })
    .catch(err => {
      if(err.response.status === 500){
        dispatch(error500())
      }
      err.response.data.text().then((e) => {
        let errorJson = JSON.parse(e)
        if(errorJson.message === "jwt expired"){
          dispatch(AuthBorrarPasaporte())
        }
      })
    });
  };

  useEffect(() => {
    if (token) {
      PDF(token);
    }
  }, [token]);

  const handleOpenSendCorreo = () => {
    setOpenSendEmail(true);
    reset();
  };

  const handleCloseSendCorreo = () => {
    setOpenSendEmail(false);
    reset();
  };

  const handleSendPdf = (e) => {
    e.preventDefault();
    if (IsValid()) {
      handleCloseSendCorreo();
      
      sendPDF(correo, dataPdf, token)
      .then(() => {
        alertEmail(true);
      })
      .catch(err => {
        if(err.response.status === 500){
          dispatch(error500())
        }
        alertEmail(false);
      })
    }
  };

  const generateJpg = () => {
    setReadyJpg(true)
    getJpg(token).then(res => {
      setReadyJpg(false)
    })
    .catch(err => {
      setReadyJpg(false)
      if(err.response.status === 500){
        dispatch(error500())
      }
      if(err.response.data.message === "jwt expired"){
        dispatch(AuthBorrarPasaporte())
      }
    })
  }

  const generatePDF = () => {
    setReadyPDF(true);
    GetDataPdf(token, dispatch).then((res) => {
      setReadyPDF(false);
    })
    .catch(err =>{
      setReadyPDF(false);
      if(err.message === "jwt expired"){
        dispatch(AuthBorrarPasaporte())
      }
    });
  };

  // validate email
  const IsValid = () => {
    if (correo) {
      if (regEmailExp.test(correo)) {
        setErrorCorreo("");
        setValidCorreo(true);
        return true;
      }
      setValidCorreo(false);
      setErrorCorreo("Formato incorrecto");
      return false;
    }
    setValidCorreo(false);
    setErrorCorreo("Este campo es requerido");
    return false;
  };

  const alertEmail = (wasSended) => {
    let message = "";
    if (wasSended) {
      message = "Certificado enviado al correo";
      setEmailWasSended(true)
    } else {
      message = "Ha ocurrido un error al intentar enviar el correo";
      setEmailWasNotSended(true);
    }    
    setEmailMessage(message);
    setTimeout(() => {
      wasSended ? setEmailWasSended(false): setEmailWasNotSended(false);  
      setEmailMessage("");
    }, 5000);
  };

  useEffect(() => {
    if (process.env.REACT_APP_WORKSPACE === "production") {
      ReactGA.event({
        category: "PasaporteGenerado",
        action: "PasaporteGenerado",
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        modalExit();
      }
    });
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleInitioClick = () => {
    dispatch(AuthBorrarPasaporte());
  };

  return (
    <Container>
      <Grid className={classes.GridLink}>
        <Link to="/vacunas" className={classes.link_1}>
          <Typography
            variant="body1"
            component="p"
            className={classes.link_text}
          >
            Ir atrás
          </Typography>
        </Link>

        <Link to="/" className={classes.link_2}>
          <Typography
            variant="body1"
            component="p"
            onClick={handleInitioClick}
            className={classes.link_text}
          >
            Ir al inicio
          </Typography>
        </Link>
      </Grid>
      <Box className={classes.container}>
        <Box
          display="flex"
          justifyContent="center"
          ref={componentRef}
          alignItems="center"
        >
          <Box
            className={classes.ticket}
            bgcolor="white"
            borderRadius="10px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box width="100%" className={classes.box1}>
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                alignItems="center"
              >
                <img
                  src={logoThree}
                  alt="ticket logo"
                  className={classes.logoImg}
                />
                <Typography className={classes.Title}>
                  CERTIFICADO DE VACUNACIÓN
                </Typography>
                <Typography className={classes.ticketTitle}>
                  VACCINATION CERTIFICATE
                </Typography>
              </Box>
              <Box className={classes.box1_info}>
                <Box className={classes.head_info}>
                  <Box className={classes.box1_info_each}>
                    <Box display="flex">
                      <Typography className={classes.box1_textOne}>
                        {" "}
                        FECHA DE EMISIÓN&nbsp;/&nbsp;
                      </Typography>
                      <Typography className={classes.box1_textTwo}>
                        {" "}
                        DATE OF ISSUE
                      </Typography>
                    </Box>
                    <Typography className={classes.box1_textThree}>
                      {createdDateEsp()}
                    </Typography>
                    <Typography className={classes.box1_textThreeEn}>
                      {createdDate()}
                    </Typography>
                  </Box>
                  <Box className={classes.box1_info_each2}>
                    <Box display="flex">
                      <Typography className={classes.box1_textOne}>
                        {" "}
                        PAÍS DE EMISIÓN&nbsp;/&nbsp;
                      </Typography>
                      <Typography className={classes.box1_textTwo}>
                        {" "}
                        ISSUING COUNTRY{" "}
                      </Typography>
                    </Box>
                    <Typography className={classes.box1_textThree}>
                      República Dominicana
                    </Typography>
                    <Typography className={classes.box1_textThreeEn}>
                      Dominican Republic
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={classes.box2} borderRadius="10px" width="100%">
              <Box className={classes.box2_info}>
                <Box className={classes.box2_info_row}>
                  <Box className={classes.box2_info_each}>
                    <Box display="flex">
                      <Typography className={classes.textOne}>
                        {" "}
                        NOMBRE&nbsp;/&nbsp;
                      </Typography>
                      <Typography className={classes.textTwo}>
                        {" "}
                        NAME{" "}
                      </Typography>
                    </Box>
                    <Typography className={classes.textName}>
                      {passport?.name.toLowerCase()}
                    </Typography>
                  </Box>
                  <Box className={classes.box2_info_each2}>
                    <Box display="flex">
                      <Typography className={classes.textOne}>
                        {" "}
                        FECHA DE NACIMIENTO&nbsp;/&nbsp;
                      </Typography>
                      <Typography className={classes.textTwo}>
                        {" "}
                        DATE OF BIRTH{" "}
                      </Typography>
                    </Box>
                    <Typography className={classes.birthDate}>
                      {mapDate(passport.birthDate)}
                    </Typography>
                    <Typography className={classes.birthDateEn}>
                      {mapDateEN(passport.birthDate)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.box2_info_row}>
                  <Box className={classes.box2_info_each}>
                    <Box display="flex">
                      <Typography className={classes.textOne}>
                        {" "}
                        APELLIDO&nbsp;/&nbsp;
                      </Typography>
                      <Typography className={classes.textTwo}>
                        {" "}
                        LAST NAME{" "}
                      </Typography>
                    </Box>
                    <Typography className={classes.textName}>
                      {passport?.lastName.toLowerCase()}
                    </Typography>
                  </Box>

                  <Box className={classes.box2_info_each2}>
                    <Box display="flex">
                      <Typography className={classes.textOne}>
                        {" "}
                        NÚMERO DE IDENTIFICACIÓN&nbsp;/&nbsp;
                      </Typography>
                      <Typography className={classes.textTwo}>
                        {" "}
                        ID NUMBER{" "}
                      </Typography>
                    </Box>
                    <Typography className={classes.birthDate}>
                      {passport.cedula}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                alignItems="center"
                className={classes.main_qr}
              >
                <Box className={classes.box_qr2}>
                  <Box onClick={handleOpen} className={classes.qr_bg}>
                    <img
                      className={classes.box_image_qr1}
                      src={qr1}
                      alt="qr1"
                    />
                  </Box>
                </Box>
                {open && (
                  <Modal
                    open={open}
                    className={classes.modalQr_box}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  >
                    <Box className={classes.qr_bg}>
                      <img
                        className={classes.box_image_qr2}
                        src={qr1}
                        alt="qr1"
                      />
                    </Box>
                  </Modal>
                )}
              </Box>
            </Box>
            <Box className={classes.box3} borderRadius="10px" width="100%">
              <Box className={classes.box3_details}>
                {passport.vaccines
                  .sort((a, b) => a.dosis - b.dosis)
                  .map((vaccine, index) => (
                    <Box
                      key={index}
                      className={classes.vaccine_info}
                      mb="0.4rem"
                    >
                      <img
                        src={bottle}
                        alt="jeringa icono"
                        className={classes.vaccine_icon}
                      />
                      <Box className={classes.vaccine_info_box}>
                        <Box display="flex">
                          <Typography className={classes.detail_text}>
                            {vaccine.vacuna_administrada}
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <Typography className={classes.detail_text1}>
                            {vaccine.tipo_vacuna}
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <Typography className={classes.detail_text1}>
                            {vaccine.laboratorio}
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <Typography className={classes.detail_text1}>
                            {" "}
                            {vaccine.dosis}
                            <span className={classes.doseNumber}>
                              {doseNumber(vaccine.dosis)}
                            </span>{" "}
                            dosis /&nbsp;{" "}
                          </Typography>
                          <Typography className={classes.detail_text2}>
                            {" "}
                            {vaccine.dosis}
                            <span className={classes.doseNumber}>
                              {doseNumberEN(vaccine.dosis)}
                            </span>{" "}
                            dose
                          </Typography>
                        </Box>
                        <Box display="flex">
                          <Typography className={classes.detail_text1}>
                            {" "}
                            {mapDate(vaccine.fecha)} /&nbsp;{" "}
                          </Typography>
                          <Typography className={classes.detail_text2}>
                            {" "}
                            {mapDateEN(vaccine.fecha)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Container className={classes.cardGrid}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={classes.buttomB}
          >
            <Box className={classes.buttons_row}>
              <Button
                className={classes.button2}
                variant="outlined"
                color="primary"
                onClick={generateJpg}
              >
                <InputAdornment
                  className={!ReadyJpg ? classes.display_none : ""}
                  position="end"
                >
                  <CircularProgress size={20} color="primary" />
                </InputAdornment>

                <img
                  className={!ReadyJpg ? classes.icons2 : classes.display_none}
                  src={jpg_icon}
                  alt="email-Icon"
                />
              </Button>
              <Button
                className={classes.button2}
                variant="outlined"
                color="primary"
                onClick={generatePDF}
              >
                <InputAdornment
                  className={!ReadyPDF ? classes.display_none : ""}
                  position="end"
                >
                  <CircularProgress size={20} color="primary" />
                </InputAdornment>

                <img
                  className={!ReadyPDF ? classes.icons2 : classes.display_none}
                  src={pdf_icon}
                  alt="email-Icon"
                />
              </Button>
            </Box>
            <Box mt={1} className={classes.buttons_row}>
              {isIos ? (
                <Button
                  className={classes.button2}
                  variant="outlined"
                  color="primary"
                  onClick={AddToWallet}
                >
                  <InputAdornment
                    className={!readyWallet ? classes.display_none : ""}
                    position="end"
                  >
                    <CircularProgress size={20} color="white" />
                  </InputAdornment>

                  <img
                    className={
                      !readyWallet ? classes.icons : classes.display_none
                    }
                    src={Apple_wallet_icon}
                    alt="email-Icon"
                  />
                  <Typography
                    className={
                      !readyWallet ? classes.textButton : classes.display_none
                    }
                    variant="body2"
                  >
                    Apple Wallet
                  </Typography>
                </Button>
              ) : (
                <Button
                  className={classes.button2}
                  variant="outlined"
                  color="primary"
                  onClick={() => setModalWallet(true)}
                >
                  <img
                    className={classes.icons}
                    src={walletpasses_icon}
                    alt="email-Icon"
                  />
                  <Typography className={classes.textButton} variant="body2">
                    WalletPasses
                  </Typography>
                </Button>
              )}
              <Button
                className={classes.button2}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleOpenSendCorreo}
              >
                <img
                  className={classes.icons}
                  src={email_icon}
                  alt="email-Icon"
                />
                <Typography variant="body2" className={classes.textButton}>
                  Email
                </Typography>
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <SendEmailModal
        openModal={OpenSendEmail}
        closeModal={setOpenSendEmail}
        handleSendPdf={handleSendPdf}
        handleCloseSendCorreo={handleCloseSendCorreo}
        IsValid={IsValid}
        correo={correo}
        handleInputChange={handleInputChange}
        validCorreo={validCorreo}
        errorCorreo={errorCorreo}
      />
      <WalletAndroidModal
        openModal={modalWallet}
        closeModal={setModalWallet}
        readyWallet={readyWallet}
        AddToWallet={AddToWallet}
      />
      {emailWasSended && (
        <Box className={classes.send_support}>
          <Alert severity="success">
            {emailWasSended && <AlertTitle>{emailMessage}</AlertTitle>}
          </Alert>
        </Box>
      )}
      {emailWasNotSended && (
        <Box className={classes.send_support}>
          <Alert severity="error">
            {emailWasNotSended && <AlertTitle>{emailMessage}</AlertTitle>}
          </Alert>
        </Box>
      )}
    </Container>
  );
}

export default VirtualTicket;
