import axios from "axios";
const apiKey = process.env.REACT_APP_X_API_KEY;

function Getfilepkpass(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/wallet`, {
        headers: {
          Authorization: "Bearer " + token,
          "X-API-KEY": apiKey,
        },
      })
      .then((response) => {
        var a = document.createElement("A");
        a.setAttribute(
          "href",
          `${process.env.REACT_APP_API_URL}/${response.data.url}`
        );
        a.click();
        resolve(true);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export { Getfilepkpass };
