import logoThree from "../../assets/images/tres-logos-fn-azul-short2.svg";
import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Modal,
  Grid,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Support } from "../soporte/Support";
import { useStyle } from "./viewPasaporte.style";
import { useDispatch, useSelector } from "react-redux";
// import syringe from "../../assets/images/medicine-blue.svg";
import syringe from "../../assets/images/ticket/bottle2.svg";
import UseModal from "../../hooks/useModal";
import { AuthBorrarPasaporte } from "../../Redux/actions/actionPassport";
import { Alert, AlertTitle } from "@material-ui/lab";
import { mapDate } from "../../util/dateFormat";
import {checkOpenTickets} from "../../Axios/checkOpenTickets";
import { error500, finishLoading, startLoading } from "../../Redux/actions/uiError";
import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

function ViewVaccines() {
  const classes = useStyle();
  const { passport, token } = useSelector((state) => state.StatePassport);
  const { loading } = useSelector((state) => state.uiError);
  const [modalActive, modalExit, handleButtonClick] = UseModal();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [msgErrorModal, setMsgErrorModal] = useState("");
  const [MsgErrorSend, setMsgErrorSend] = useState("");
  const { strapiData, strapiError } = useSelector((state) => state.strapiData);

  const doseNumber = (dose) => {
    if (dose === 1) return "era";
    if (dose === 2) return "da";
    if (dose === 3) return "era";
    if (dose === 4) return "ta";
  };

  const handleOpenModal = (mss) => {
    setMsgErrorModal(mss);
    setOpenModal(true);
  };
  const eliminarErrores = () => {
    setTimeout(() => {
      setMsgErrorSend();
    }, 8000);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseOpenTickets = () => {
    setOpenTickets(0);
  };

  const handleInitioClick = () => {
    dispatch(AuthBorrarPasaporte());
  };
  const [openTickets, setOpenTickets] = useState(0);

   //solicitud en curso mensaje
   const soliFirstIndex = strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.indexOf('{');
   const soliSecondIndex = strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.indexOf('}');
 
   let solicitudEnCurso = {};
   let solicitudEnCursoMensage = ''; 
 
   if(soliFirstIndex === -1) {
     solicitudEnCurso = {
       firstPart:  strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(0, soliSecondIndex),
       secondPart: strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(soliSecondIndex, strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.length)
     }
     solicitudEnCursoMensage = `${solicitudEnCurso.firstPart}${solicitudEnCurso.secondPart}`;
   } else if(soliSecondIndex === -1) {
     solicitudEnCurso = {
       firstPart:  strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(0, soliFirstIndex),
       secondPart: strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(soliFirstIndex, strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.length)
     }
     solicitudEnCursoMensage = `${solicitudEnCurso.firstPart}${solicitudEnCurso.secondPart}`;
 
   } else {
     solicitudEnCurso = {
       firstPart:  strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(0, soliFirstIndex),
       secondPart: strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.substring(soliSecondIndex+1, strapiData?.informacionAlCrearTicket?.solicitudesEnCurso.length)
     }
     solicitudEnCursoMensage = `${solicitudEnCurso.firstPart} ${openTickets} ${solicitudEnCurso.secondPart}`;
   } 

  const handleOpenAsistencia = () => {
    dispatch(startLoading());
    checkOpenTickets(token, passport.cedula).then((res) => {
      if (res.open > 0) {
        setOpenTickets(res.open);
        dispatch(finishLoading());
      } else {
        handleButtonClick();
        dispatch(finishLoading());
      }
    })
    .catch(err => {
      if(err.response.status === 500){
        dispatch(error500())
      }
      if(err.message === "jwt expired"){
        dispatch(AuthBorrarPasaporte())
      }
      dispatch(finishLoading());
    })
  };

  useEffect(() => {
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        modalExit();
      }
    });
  }, []);

  return (
    <Container className={classes.main}>
      <Box width="100%">
        <Box
          display="flex"
          flexDirection="column"
          margin="auto"
          width="100%"
          alignItems="center"
        >
          <img src={logoThree} alt="" className={classes.logo} />
          <Typography variant="h5" className={classes.text}>
            Información de Vacunas
          </Typography>
        </Box>
        {passport.vaccines
          .sort((a, b) => a.dosis - b.dosis)
          .map((vaccine, index) => (
            <>
              <Box
                width="100%"
                mb={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
                key={index}
              >
                <img src={syringe} alt="" className={classes.vaccine_icon} />
                <Box className={classes.BoxVaccuna}>
                  <Typography className={classes.textDosis}>
                    {vaccine.vacuna_administrada}{" "}
                  </Typography>

                  <Typography className={classes.textDosis1}>
                    {vaccine.tipo_vacuna}{" "}
                  </Typography>

                  <Typography className={classes.textDosis1}>
                    {vaccine.laboratorio}{" "}
                  </Typography>

                  <Box display="flex">
                    <Typography className={classes.textDosis1}>
                      {" "}
                      {vaccine.dosis}
                      <span className={classes.doseNumber}>
                        {doseNumber(vaccine.dosis)}
                      </span>{" "}
                      dosis
                    </Typography>
                  </Box>

                  <Box display="flex">
                    <Typography className={classes.textDosis1}>
                      {" "}
                      {mapDate(vaccine.fecha)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          ))}
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          className={classes.boxSoporte}
        >
          <Typography className={classes.textInfo}>
            Por favor, verifique cuidadosamente los datos antes de continuar. En
            caso de ser necesario,
            <Link onClick={handleOpenAsistencia} className={classes.textModal}>
              solicite asistencia
            </Link>
            .
          </Typography>
        </Box>

        {MsgErrorSend && (
          <Grid className={classes.gridError}>
            <Alert severity="error">
              <AlertTitle>{MsgErrorSend}</AlertTitle>
            </Alert>
          </Grid>
        )}

        {MsgErrorSend ? eliminarErrores() : ""}

        <Box
          width="100%"
          justifyContent="space-evenly"
          className={classes.box_buttons}
        >
          <Link to="/" className={classes.link}>
            <Button
              variant="outlined"
              className={classes.btn_back}
              onClick={handleInitioClick}
            >
              Ir atrás
            </Button>
          </Link>

          <Link to="/certificado" className={classes.link}>
            <Button variant="contained" className={classes.btn_next}>
              Continuar
            </Button>
          </Link>
        </Box>
      </Box>

      <Modal
        className={classes.support_modal}
        open={modalActive}
        onClose={modalExit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Support
          handleOpenModal={handleOpenModal}
          handleCloseSupport={modalExit}
          setMsgErrorSend={setMsgErrorSend}
        />
      </Modal>
      <Modal className={classes.modalEnviar} open={openModal}>
        <Box className={classes.boxModalEnviar}>
          <Box className={classes.modal_content}>
            <Typography
              align="justify"
              className={`${
                msgErrorModal ? classes.modal_text_error : classes.modal_text
              }`}
            >
              {
              msgErrorModal
                ? `Error en campo ${Object.keys(
                    msgErrorModal
                  )}: ${Object.values(msgErrorModal)}`
                : 
                  <>
                    {
                      strapiError ?
                      "Gracias por enviarnos sus datos. Nuestro equipo estará revisando su caso en el plazo de 10 días laborables. Le notificaremos al email especificado."
                      :
                      <>
                        {strapiData?.informacionAlCrearTicket?.mensajeAlEnviar ?? ''}                      
                      </>
                    }
                  </>
              }
            </Typography>
            <Box className={classes.modal_box_button}>
              <Button
                onClick={handleCloseModal}
                className={classes.modal_button}
              >
                Entendido
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal className={classes.modalEnviar} open={openTickets}>
        <Box className={classes.boxModalEnviar}>
          <Box className={classes.modal_content}>
            <Typography align="justify" className={classes.modal_text}>
              {
                strapiError ?
                  "En este momento no le es posible crear un ticket de asistencia, ya que cuenta con uno o varios tickets pendientes todavía."
                :
                  <ReactMarkDown 
                    children={solicitudEnCursoMensage}
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm]}/>
              }
            </Typography>
            <Box className={classes.modal_box_button}>
              <Button
                onClick={handleCloseOpenTickets}
                className={classes.modal_button}
              >
                Ok, entendido
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size={100} color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default ViewVaccines;
