import { Provider } from "react-redux";
import { store } from "./Redux/store/store";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { custom_theme } from "./theme";
import AppRoutes from "./Routes/AppRoutes";
import ReactGa from 'react-ga';

if(process.env.REACT_APP_WORKSPACE === "production"){
  const GAKey = process.env.REACT_APP_GA_KEY;
  ReactGa.initialize(GAKey);
}

const useGlobalStyles = makeStyles({
  "@global": {
    body: {
      margin: "0",
      padding: "0",
      height: "100vh"
    },
  },
});


function MyThemProvider({ children }) {
  useGlobalStyles();
  return <ThemeProvider theme={custom_theme}>{children}</ThemeProvider>;
}

function App() {

  return (
    <MyThemProvider>
      <Provider store={store}>
        <AppRoutes/>
      </Provider>
    </MyThemProvider>
  );
}

export default App;
