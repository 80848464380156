import { Typography } from "@material-ui/core";
import useStyles from "./styles";

export default function Footer() {
  const classes = useStyles();
  return (
    <>
      <footer className={classes.footer}>
        <div>
          <Typography component="h3" className={classes.footer_title} variant="body1" >
            Ministerio de Salud Pública y Asistencia Social
          </Typography>
          <address>
            <Typography>
              Av. Dr. Héctor Homero Hérnandez, Esq. Av. Tiradentes, Ens. La Fe
              Santo Domingo, R. D. 10514.
              <br />
              Tel: <a href="tel: +1 809 541 3121">+1 (809) 541-3121</a> | Email: <a href="mailto: info@ministeriodesalud.gob.do">info@ministeriodesalud.gob.do</a>
            </Typography>
          </address>
        </div>
        <div className={classes.copyright}>
          <Typography variant="body2" >
            © {new Date().getFullYear()} - Todos los derechos reservados.
          </Typography>
        </div>
      </footer>
    </>
  );
}
