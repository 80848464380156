const TYPES = {
  login: "LOGIN",
  Salve:"Salve",
  logout: 'LOGOUT',
  openTickets: "OpenTickets",

  UISetError: "UISetError",
  UIRemoveError: "UIRemoveError",

  uiStartLoading: "uiStartLoading",
  uiFinishLoading: "uiFinishLoading",

  uiStartLoading2: "uiStartLoading2",
  uiFinishLoading2: "uiFinishLoading2",

  strapiData: "StrapiData",
  strapiError : "StrapiError",

  uiError500: "uiError500",
  removeError500: "removeError500"
};

export { TYPES };

