import { useEffect, useState} from "react";
import axios from "axios";
import { finishLoading2, startLoading2 } from "../Redux/actions/uiError";
import { useDispatch } from "react-redux";

const strapiBaseUrl = process.env.REACT_APP_STRAPI_URL;

const useStrapi = (view) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch(startLoading2())
        axios.get(`${strapiBaseUrl}${view}`)
        .then((strapiData) => {
            setData(strapiData.data)
            dispatch(finishLoading2())
        }).catch((error) => {
            setError("Error al cargar datos dinámicos")
            dispatch(finishLoading2())
        })
    }, [view]);

    return [data, error];
};

export default useStrapi;