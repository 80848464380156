import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  OutlinedInput,
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal,
  CircularProgress,
  Backdrop,
  Grid,
} from "@material-ui/core";
import { useStyles } from "./asistencia.styles";
import logoThree from "../../assets/images/tres-logos-fn-azul-short2.svg";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from '@material-ui/icons/Delete';
import { useForm } from "../../hooks/useForm";
import { sendForm } from "../../Axios/sendForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  finishLoading,
  removeError,
  setError,
  startLoading,
  error500
} from "../../Redux/actions/uiError";
import { AuthBorrarPasaporte } from "../../Redux/actions/actionPassport";
import ReactGA from "react-ga";
import { Alert, AlertTitle } from "@material-ui/lab";
export default function Asistencia() {
  const classes = useStyles();
  const regEmailExp = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
  const { tokenP } = useSelector(
    (state) => state.StatePassport
  );
  const { loading, msgError } = useSelector((state) => state.uiError);
  const [validCorreo, setValidCorreo] = useState(true);
  const [errorCorreo, setErrorCorreo] = useState("");

  const [errorDocument, setErrorDocument] = useState("");

  const [Values, handleInputChange] = useForm({
    correo: "",
    comentarios: "",
  });

  const { correo, comentarios } = Values;
  const [documento, setDocumento] = useState([]);
  const [fileMessage, setFileMessage] = useState("");
  const [maxNumberFiles, setMaxNumberFiles] = useState("");
  const [fileType, setFileType] = useState("");
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [maxComentario, setMaxComentario] = useState(false);
  const [msgErrorModal, setMsgErrorModal] = useState("");
  const [MsgerrorTicket, setMsgerrorTicket] = useState();
  const { strapiData, strapiError } = useSelector((state) => state.strapiData);
  const content = strapiData?.vistaAsistencia ?? '';

  const eliminarErrores = () => {
    setTimeout(() => {
      setMsgerrorTicket();
    }, 8000);
  };

  useEffect(() => {
    if (process.env.REACT_APP_WORKSPACE === "production") {
      ReactGA.event({
        category: "AsistenciaPagina",
        action: "AsistenciaPagina",
      });
    }
  }, []);

  useEffect(() => {
    if (comentarios.length + msgError.length === 500) {
      setMaxComentario(true);
      setTimeout(() => {
        setMaxComentario(false);
      }, 2000);
    }
  }, [comentarios, msgError]);

  useEffect(() => {
    if (opened) documentIsValid();
  }, [documento]);

  const handleOpenModal = (mss) => {
    setMsgErrorModal(mss);
    setOpenModal(true);
  };

  const eliminarErroresRedux = () => {
    dispatch(removeError());
    dispatch(AuthBorrarPasaporte());
  };

  const showFileNames = (files) => {
    let fileNames = "";
    for (let file of files) {
      if(files.length === 2 || documento.length === 2){
        fileNames += file.name.substring(0, 10) + "... , ";
      }else{
        fileNames += file.name + ", ";
      }
    }
    return fileNames.substring(0, fileNames.length - 2);
  };

  const resetFilesMessage = () => {
    setTimeout(() => {
      setFileMessage("");
      setMaxNumberFiles("");
      setFileType("");
    }, 3000);
  };

  const handleDocumentChange = (e) => {
    let allFiles = [];
    const allowFiles = ["application/pdf", "image/png", "image/jpeg"];
    for (let file of e.target.files) {
      if (!allowFiles.includes(file.type)) {
        setFileType(`El formato ${file.type} no es permitido`);
        setDocumento([]);
        return resetFilesMessage();
      }

      if (file.size > 8388608) {
        setFileMessage("Límite de 8 megas por archivo");
        return resetFilesMessage();
      }
      allFiles.push(file);
    }
    if (documento.length + allFiles.length > 2) {
      setMaxNumberFiles("Permitido 2 archivos máximo");
      setDocumento([...documento]);
      return resetFilesMessage();
    }

    setDocumento([...documento, ...allFiles]);
  };

  const cleanInput = () => {
    if(documento.length > 0){
      let copy = [...documento]
      copy.pop()
      setDocumento(copy)
    }
  }
  
  const fileClick = (e) => {
    e.target.value = ""
  }

  const documentIsValid = () => {
    if (documento.length > 0) {
      setErrorDocument("");
      return true;
    }
    setErrorDocument("Este campo es requerido");
    return false;
  };

  const correoIsValid = () => {
    if (correo) {
      if (regEmailExp.test(correo)) {
        setErrorCorreo("");
        setValidCorreo(true);
        return true;
      }
      setValidCorreo(false);
      setErrorCorreo("Formato incorrecto");
      return false;
    }
    setValidCorreo(false);
    setErrorCorreo("Este campo es requerido");
    return false;
  };

  const formValid = () => {
    correoIsValid();
    documentIsValid();
  //  handleDateBlur();
    if (correoIsValid() && documentIsValid()) {
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    eliminarErroresRedux();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValid()) {
      dispatch(startLoading());
      sendForm({
        correo,
        documento,
        comentarios: `${msgError} ${comentarios}`,
        tokenP,
      }).then((res) => {
        if (res.status === 400) {
          dispatch(finishLoading());
          setMsgerrorTicket(
            "Estamos presentando un problema por lo que su " +
              "solicitud de Asistencia no se ha podido enviar, por favor intentarlo más tarde."
          );
        } else if (res.status === 201) {
          dispatch(setError(res.status));
          handleOpenModal("");
          dispatch(finishLoading());
        }else if (res.status === 500) {
          dispatch(error500())
        }
      });
    }
  };

  return (
    <Container className={classes.content}>
      <Box>
        <img src={logoThree} alt="logo" className={classes.logo} />{" "}
      </Box>

      <Typography variant="h5" component="h1" m={1} className={classes.title}>
        Certificado de Vacunación
      </Typography>
      <Typography align="justify" className={classes.info}>
        {
          strapiError ?
            <Grid className={classes.gridMessage}>
              <Alert severity="error">
                <AlertTitle>
                  {strapiError}
                </AlertTitle>
              </Alert>
            </Grid>
          :
            <>
             {content.contenido}
            </>
        }
      </Typography>
      <form onSubmit={handleSubmit} className={classes.support_form}>
        <FormControl className={`${classes.select}`}>
          <label>
            <Typography className={classes.type_color} variant="h6">
              Anexar tarjeta(s) de vacunación *
            </Typography>
          </label>
          <TextField
            onBlur={documentIsValid}
            className={classes.textField_input}
            value={
              documento.length > 0
                ? showFileNames(documento)
                : "Sin archivos seleccionados"
            }
            variant="outlined"
            InputProps={{
              className: documento.length === 0 && classes.anexar,
              readOnly: true,
              endAdornment:(
                <IconButton onClick={cleanInput}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              ),
              startAdornment: (
                <InputAdornment
                  onClick={() => setOpened(true)}
                  position="start"
                >
                  <input
                    multiple
                    name="documento"
                    id="icon-button-file"
                    type="file"
                    onClick={fileClick}
                    onChange={handleDocumentChange}
                    hidden
                    accept="application/pdf, image/png, image/jpeg"
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton component="span">
                      <AttachFileIcon fontSize="small" />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        {errorDocument && (
          <Typography align="left" className={classes.error_color}>
            {errorDocument}
          </Typography>
        )}
        <FormHelperText className={classes.type_color + " " + classes.fontsize}>
          Hasta dos archivos (JPG, PNG o PDF)
          <br /> Cada archivo de hasta 8 MB
        </FormHelperText>
        {(fileMessage || maxNumberFiles || fileType) && (
          <>
            {fileMessage && (
              <Typography align="left" className={classes.error_color}>
                {fileMessage}
              </Typography>
            )}
            {maxNumberFiles && (
              <Typography align="left" className={classes.error_color}>
                {maxNumberFiles}
              </Typography>
            )}
            {fileType && (
              <Typography align="left" className={classes.error_color}>
                {fileType}
              </Typography>
            )}
          </>
        )}

        <FormControl className={classes.select}>
          <label>
            <Typography className={classes.type_color} variant="h6">
              Correo electrónico *
            </Typography>
          </label>
          <OutlinedInput
            onBlur={correoIsValid}
            name="correo"
            value={correo}
            onChange={handleInputChange}
            placeholder="example@gmail.com"
          />
        </FormControl>
        {!validCorreo && (
          <Typography align="left" className={classes.error_color}>
            {errorCorreo}
          </Typography>
        )}
        <FormControl className={classes.select}>
          <label>
            <Typography className={classes.type_color} variant="h6">
              Comentarios
            </Typography>
          </label>
          <TextField
            inputProps={{ maxLength: 500 - msgError.length }}
            name="comentarios"
            value={comentarios}
            onChange={handleInputChange}
            variant="outlined"
            multiline
            minRows={2}
            maxRows={4}
          />
        </FormControl>
        {maxComentario && (
          <Typography align="left" className={classes.error_color}>
            500 carácteres máximo
          </Typography>
        )}

        {MsgerrorTicket && (
          <Grid className={classes.gridError}>
            <Alert severity="error">
              <AlertTitle>{MsgerrorTicket}</AlertTitle>
            </Alert>
          </Grid>
        )}

        {MsgerrorTicket ? eliminarErrores() : ""}

        <Box
          my={2}
          display="flex"
          justifyContent="space-around"
          className={classes.buttons_content}
        >
          <Button
            className={`${classes.support_button} ${classes.button}`}
            variant="contained"
            onClick={eliminarErroresRedux}
          >
            Ir atrás
          </Button>

          <Button
            className={`${classes.reportar} ${classes.button}`}
            type="submit"
            color="primary"
            variant="contained"
          >
            Enviar
          </Button>
        </Box>
      </form>
      <Modal className={classes.modalEnviar} open={openModal}>
        <Box className={classes.boxModalEnviar}>
          <Box className={classes.modal_content}>
            <Typography align="justify" className={classes.modal_text}>
              {msgErrorModal
                ? `Error en campo ${Object.keys(
                    msgErrorModal
                  )}: ${Object.values(msgErrorModal)}`
                : 
                  <>
                    {
                      strapiError ?
                      "Gracias por enviarnos sus datos. Nuestro equipo estará revisando su caso en el plazo de 10 días laborables. Le notificaremos al email especificado."
                      :
                      <>
                        {strapiData?.informacionAlCrearTicket?.mensajeAlEnviar ?? ''}                      
                      </>
                    }
                  </>
              }
            </Typography>
            <Box className={classes.modal_box_button}>
              <Button
                onClick={handleCloseModal}
                className={classes.modal_button}
              >
                Entendido, ir al inicio
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size={100} color="inherit" />
      </Backdrop>
    </Container>
  );
}
