import axios from "axios";
const base_url = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_X_API_KEY;

const headers = {
  "X-API-KEY": apiKey
}

async function SendDataVacunnate(code) {
  try {
    return await axios.post(base_url + "/code-verifier", {codigo: code}, {
      headers: headers
    });
  } catch (error) {
    throw error;
  }
}

async function SendDataHash(hash) {
  try {
    return await axios.post(base_url + "/getUserDataByHash", {Hash: hash}, {
      headers: headers
    });
  } catch (error) {
    throw error;
  }
}


export { SendDataVacunnate, SendDataHash };
