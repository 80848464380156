import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { useStyles } from "./view2.style.js";
import logoThree from "../../assets/images/tres-logos-fn-azul-short2.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthBorrarPasaporte } from "../../Redux/actions/actionPassport.js";
import { withStyles } from "@material-ui/core/styles";
import { acceptConsents } from "../../Axios/consents.js";
import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { error500 } from "../../Redux/actions/uiError.js";

export default function View2() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { passport, token } = useSelector((state) => state.StatePassport);
  const { strapiData } = useSelector((state) => state.strapiData);
  const content = strapiData?.vistaTerminos ?? 'none';

  const handleInitioClick = () => {
    dispatch(AuthBorrarPasaporte());
  };
  const [isChecked, setIsChecked] = useState(false);
  const handlecheck = () => {
    setIsChecked(!isChecked);
  };
  const handleAcceptTerms = () => {
    acceptConsents(passport.code, token)
    .then()
    .catch((error) => {
      if(error.response.status === 500){
        dispatch(error500())
      }
    })
  };

  const WhiteCheckbox = withStyles({
    root: {
      color: "#00205b",
      "&$checked": {
        color: "#00205b",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <Container className={classes.content}>
      <Box>
        <img src={logoThree} alt="logo" className={classes.logo} />{" "}
      </Box>

      <Typography variant="h5" component="h1" className={classes.title}>
        Certificado de Vacunación
      </Typography>

      <Box className={classes.paragraph}>
        <Typography align="center" className={classes.title_term}>
          <ReactMarkDown 
            children={content?.titulo}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}/>
        </Typography>
        <Typography align="left" className={classes.subtitle_term}>
          {content?.seccion1?.titulo ?? ''}
        </Typography>
        <Typography align="left">
          <ReactMarkDown 
            children={content?.seccion1?.contenido ?? ''}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}/>         

        </Typography>
        <Typography align="left" className={classes.subtitle_term}>
          {content?.seccion2?.titulo ?? ''}
        </Typography>
        <Typography align="left">

          <ReactMarkDown 
            children={content?.seccion2?.contenido ?? ''}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}/>   
         
        </Typography>
        <Typography align="left" className={classes.subtitle_term}>
          {content?.seccion3?.titulo ?? ''}
        </Typography>
        <Typography align="left">
          <ReactMarkDown 
            children={content?.seccion3?.contenido ?? ''}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}/>   

          <Typography className={classes.title}>
            {content?.seccion4?.titulo ?? ''}
          </Typography>

          <ReactMarkDown 
            children={content?.seccion4?.contenido ?? ''}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}/> 

          <Typography className={classes.title}>
            {content?.seccion5?.titulo ?? ''}
          </Typography>
          <ReactMarkDown 
            children={content?.seccion5?.contenido ?? ''}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}/> 
        </Typography>
        <Typography align="left" className={classes.subtitle_term}>
          {content?.seccion6?.titulo ?? ''}
          CONTACTOS PARA CONSULTAS O RECLAMACIONES
        </Typography>
        <Typography align="left">
          <ReactMarkDown 
            children={content?.seccion6?.contenido ?? ''}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}/> 
        </Typography>
      </Box>
      <FormGroup className={classes.checkbox}>
        <FormControlLabel
          control={
            <WhiteCheckbox
              className={classes.check}
              onClick={handlecheck}
              checked={isChecked}
              name="chec"
            />
          }
          label={content?.textoCheckBox}
          align="left"
        />
      </FormGroup>

      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        flexWrap="wrap"
        mt={4}
        className={classes.boxButton}
      >
        <Link to="/" className={classes.link}>
          <Button
            onClick={handleInitioClick}
            variant="outlined"
            className={classes.button}
          >
            Ir atrás
          </Button>
        </Link>
        {isChecked ? (
          <Link to="/vacunas" className={classes.link}>
            <Button
              onClick={handleAcceptTerms}
              variant="contained"
              className={classes.active}
            >
              Continuar
            </Button>
          </Link>
        ) : (
          <Box>
            {" "}
            <Button variant="contained" className={classes.desactive} disabled>
              Continuar
            </Button>{" "}
          </Box>
        )}
      </Box>
    </Container>
  );
}
