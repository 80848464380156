import React from 'react';
import {useSelector} from "react-redux";
import {Route, Redirect} from "react-router-dom";

function PrivateRoute({component: Component, ...rest}) {
    const { passport } = useSelector((state) => state.StatePassport);

    return (
        <div>
            <Route{...rest}>
                {passport?
                    <Component/>
                    : <Redirect to={"/"}/>
                }
            </Route>
        </div>
    );
}
export default PrivateRoute;