import { useState } from "react";



export default function UseModal (){
    const [modalActive, setModalActive] = useState(false)
   

    const handleButtonClick = () => {
            setModalActive(true);

    }
     const modalExit = () =>{
         setModalActive(false);
     }
    
    return [modalActive, modalExit, handleButtonClick]

}