import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import { authReducer } from "../reducers/authReducer";
import thunk from "redux-thunk";
import { UIReducer } from "../reducers/UIReducer";
import { strapiReducer } from "../reducers/strapiReducer";
//Extension para redux-dev-tool
const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const reducer = combineReducers({
  StatePassport: authReducer,
  uiError: UIReducer,
  strapiData: strapiReducer
});

export const store = createStore(
  reducer,
  composeEnhancers(
    //Milldeware
    applyMiddleware(thunk)
  )
);
