import axios from "axios";
const apiKey = process.env.REACT_APP_X_API_KEY;

export async function sendForm(data = {}) {
  const { tokenP, comentarios, documento, correo } = data
  const formData = new FormData()

  formData.append("user_email", correo);
  formData.append("comentario", comentarios)
  documento.forEach((doc) => {
    formData.append("files", doc);
  })

  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/tickets`,
    data: formData,
    headers: {
      Authorization: "Bearer " + tokenP,
      "X-API-KEY": apiKey
    },
  }).catch((error) => error.response);
}
