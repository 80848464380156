import {
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  Button,
  Box,
  FormHelperText,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyle } from "./support.styles";
import { useForm } from "../../hooks/useForm";
import { sendForm } from "../../Axios/sendForm";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { finishLoading, startLoading } from "../../Redux/actions/uiError";
import { AuthBorrarPasaporte } from "../../Redux/actions/actionPassport";

const initialState = {
  correo: "",
  comentarios: "",
};

export const Support = ({
  handleOpenModal,
  handleCloseSupport,
  setMsgErrorSend,
}) => {
  const classes = useStyle();
  const regEmailExp = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
  const { token } = useSelector((state) => state.StatePassport);
  const { loading } = useSelector((state) => state.uiError);
  const [errorCorreo, setErrorCorreo] = useState("");
  const [errorComentario, setErrorComentario] = useState("");
  const [Values, handleInputChange] = useForm(initialState);
  const { correo, comentarios } = Values;
  const [documento, setDocumento] = useState([]);
  const [fileMessage, setFileMessage] = useState("");
  const [maxNumberFiles, setMaxNumberFiles] = useState("");
  const [fileType, setFileType] = useState("");
  const [opened, setOpened] = useState(false);
  const [errorDocument, setErrorDocument] = useState("");
  const [maxComentario, setMaxComentario] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (process.env.REACT_APP_WORKSPACE === "production") {
      ReactGA.event({
        category: "AsistenciaModal",
        action: "AsistenciaModal",
      });
    }
  }, []);

  useEffect(() => {
    if (comentarios.length === 500) {
      setMaxComentario(true);
      setTimeout(() => {
        setMaxComentario(false);
      }, 2000);
    }
  }, [comentarios]);

  useEffect(() => {
    if (opened) documentIsValid();
  }, [documento]);

  const showFileNames = (files) => {
    let fileNames = "";
    for (let file of files) {
      if(files.length === 2 || documento.length === 2){
        fileNames += file.name.substring(0, 10) + "... , ";
      }else{
        fileNames += file.name + ", ";
      }
    }
    return fileNames.substring(0, fileNames.length - 2);
  };

  const resetFilesMessage = () => {
    setTimeout(() => {
      setFileMessage("");
      setMaxNumberFiles("");
      setFileType("");
    }, 3000);
  };

  const handleDocumentChange = (e) => {
    let allFiles = [];
    const allowFiles = ["application/pdf", "image/png", "image/jpeg"];
    for (let file of e.target.files) {
      if (!allowFiles.includes(file.type)) {
        setFileType(`El formato ${file.type} no es permitido`);
        setDocumento([]);
        return resetFilesMessage();
      }

      if (file.size > 8388608) {
        setFileMessage("Límite de 8 megas por archivo");
        return resetFilesMessage();
      }
      allFiles.push(file);
    }
    if (documento.length + allFiles.length > 2) {
      setMaxNumberFiles("Permitido 2 archivos máximo");
      setDocumento([...documento]);
      return resetFilesMessage();
    }

    setDocumento([...documento, ...allFiles]);
  };

  const cleanInput = () => {
    if(documento.length > 0){
      let copy = [...documento]
      copy.pop()
      setDocumento(copy)
    }
  }

  const fileClick = (e) => {
    e.target.value = ""
  }

  const correoIsValid = () => {
    if (correo) {
      if (regEmailExp.test(correo)) {
        setErrorCorreo("");
        return true;
      }
      setErrorCorreo("Formato incorrecto");
      return false;
    }
    setErrorCorreo("Este campo es requerido");
    return false;
  };

  const comentarioIsValid = () => {
    if (comentarios.length > 0) {
      setErrorComentario("");
      return true;
    }
    setErrorComentario("Este campo es requerido");
    return false;
  };

  const documentIsValid = () => {
    if (documento.length > 0) {
      setErrorDocument("");
      return true;
    }
    setErrorDocument("Este campo es requerido");
    return false;
  };

  const formValid = () => {
    correoIsValid();
    documentIsValid();
    comentarioIsValid();
    if (correoIsValid() && documentIsValid() && comentarioIsValid()) {
      return true;
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValid()) {
      dispatch(startLoading());
      sendForm({
        correo,
        documento,
        comentarios,
        tokenP: token,
      }).then((res) => {
        if (res.status === 400 || res.status === 401) {
          if(res.data.message === "jwt expired"){
            dispatch(AuthBorrarPasaporte())
          }
          dispatch(finishLoading());
          setMsgErrorSend(
            "Estamos presentando un problema por lo que su " +
              "solicitud de Asistencia no se ha podido enviar, por favor intentarlo más tarde."
          );
        } else if (res.status === 201) {
          handleOpenModal("");
          dispatch(finishLoading());
        }
        handleCloseSupport();
      })
    }
  };

  return (
    <Container className={classes.container}>
      <Typography className={classes.title} variant="h5">
        Solicitar asistencia
      </Typography>
      <form onSubmit={handleSubmit} className={classes.support_form}>
        <FormControl className={classes.select}>
          <label>
            <Typography className={classes.type_color} variant="h6">
              Anexar documentación *
            </Typography>
          </label>
          <TextField
            className={classes.input_field}
            onBlur={documentIsValid}
            value={
              documento.length > 0
                ? showFileNames(documento)
                : "Sin archivos seleccionados"
            }
            variant="outlined"
            InputProps={{
              className: documento.length === 0 && classes.anexar,
              readOnly: true,
              endAdornment:(
                <IconButton onClick={cleanInput}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              ),
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => setOpened(true)}
                >
                  <input
                    multiple
                    name="documento"
                    id="icon-button-file"
                    type="file"
                    onClick={fileClick}
                    onChange={handleDocumentChange}
                    hidden
                    accept="application/pdf, image/png, image/jpeg"
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton component="span">
                      <AttachFileIcon fontSize="small" />
                    </IconButton>
                  </label>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        {errorDocument && (
          <Typography align="left" className={classes.error_color}>
            {errorDocument}
          </Typography>
        )}
        <FormHelperText className={`${classes.type_color} ${classes.fontsize}`}>
          Hasta dos archivos (JPG, PNG o PDF) <br />
          Cada archivo de hasta 8 MB
        </FormHelperText>
        {(fileMessage || maxNumberFiles || fileType) && (
          <>
            {fileMessage && (
              <Typography className={classes.error_color}>
                {fileMessage}
              </Typography>
            )}
            {maxNumberFiles && (
              <Typography className={classes.error_color}>
                {maxNumberFiles}
              </Typography>
            )}
            {fileType && (
              <Typography className={classes.error_color}>
                {fileType}
              </Typography>
            )}
          </>
        )}

        <FormControl className={classes.select}>
          <label>
            <Typography className={classes.type_color} variant="h6">
              Correo electrónico *
            </Typography>
          </label>
          <OutlinedInput
            className={classes.input_field}
            onBlur={correoIsValid}
            name="correo"
            value={correo}
            onChange={handleInputChange}
            placeholder="example@gmail.com"
          />
        </FormControl>
        {errorCorreo && (
          <Typography className={classes.error_color}>{errorCorreo}</Typography>
        )}
        <FormControl className={classes.select}>
          <label>
            <Typography className={classes.type_color} variant="h6">
              Comentarios *
            </Typography>
          </label>
          <TextField
            inputProps={{ maxLength: 500 }}
            className={classes.input_field}
            name="comentarios"
            value={comentarios}
            onChange={handleInputChange}
            onBlur={comentarioIsValid}
            variant="outlined"
            multiline
            minRows={2}
            maxRows={4}
          />
        </FormControl>
        {errorComentario && (
          <Typography align="left" className={classes.error_color}>
            {errorComentario}
          </Typography>
        )}
        {maxComentario && (
          <Typography align="left" className={classes.error_color}>
            500 carácteres máximo
          </Typography>
        )}
        <Box
          my={2}
          display="flex"
          justifyContent="space-around"
          className={classes.buttons_content}
        >
          <Button
            className={`${classes.support_button} ${classes.button}`}
            onClick={handleCloseSupport}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            className={`${classes.reportar} ${classes.button}`}
            type="submit"
            variant="contained"
          >
            Enviar
          </Button>
        </Box>
      </form>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size={100} color="inherit" />
      </Backdrop>
    </Container>
  );
};
