import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  container: {
    margin: "auto 1rem",
    padding: "1rem",
    backgroundColor: "white",
    minHeight: "400px",
    maxWidth: "400px",
    borderRadius: "0.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "auto",
    border: "2px solid #00205b",
    "@media screen and (max-width: 500)": {
      width: "auto",
    },
  },
  title: {
    color: "#00205b",
    margin: "2rem 0 1rem 0",
    fontWeight: "bold",
  },
  support_form: {
    width: "90%",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    margin: "0.7rem 0",
    textAlign: "center",
  },
  type_color: {
    color: "#00205b",
    fontSize: "16px",
    fontWeight: "bold",
  },
  input_field: {
    backgroundColor: "white",
  },
  anexar: {
    color: "#8080806e",
  },
  error_color: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  fontsize: {
    fontSize: "14px",
    fontWeight: "normal",
  },
  buttons_content: {
    justifyContent: "space-around",
    "@media screen and (max-width: 350px)": {
      flexDirection: "column",
    },
  },
  support_button: {
    fontSize: "20px",
    width: "45%",
    color: "#00205b",
    fontWeight: "bold",
    backgroundColor: "#fff",
    borderRadius: "0.5rem",
    border: "2px solid #00205b",
    "@media screen and (max-width: 350px)": {
      margin: "0 0 1rem 0",
      fontSize: "15px",
      width: "20%",
      height: "45px",
    },
    textTransform: "none",
  },
  reportar: {
    fontSize: "20px",
    width: "45%",
    textTransform: "none",
    color: "#fff",
    backgroundColor: "#00205b",
    borderRadius: "0.5rem",
    fontWeight: "bold",
    "@media screen and (max-width: 350px)": {
      fontSize: "15px",
      width: "20%",
      height: "45px",
    },
    "&:hover": {
      backgroundColor: "#00207b",
    },
  },
  button: {
    minWidth: "100px",
    "@media screen and (max-width: 350px)": {
      width: '100%'
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFF",
  },
  helperText: {
    marginTop: "5px",
    marginLeft: "2px",
    fontSize: "16px",
    fontWeight: "bold",
  },
}));
