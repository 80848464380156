import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  box_strapi_loading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    alignItems: "center",
  },
  strapi_loading: {
    color: "#00205b",
    padding: "20% 0",
  },
  vacunate_logo:{
    position: "absolute",
    width: "60px"
  }
}));
