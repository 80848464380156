import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import IconButton from "@material-ui/core/IconButton";

export default function SocialMedia() {
  return (
    <>
      <IconButton
        href="https://www.instagram.com/vacunaterd/"
        target="__blank"
        aria-label="Instagram"
        style={{ margin: "10px" }}
      >
        <InstagramIcon style={{ fontSize: "40px", color: "#00205C" }} />
      </IconButton>
      <IconButton
        href="https://twitter.com/vacunaterd"
        target="__blank"
        aria-label="Twitter"
        style={{ margin: "10px" }}
      >
        <TwitterIcon style={{ fontSize: "40px", color: "#00205C" }} />
      </IconButton>
      <IconButton
        href="https://www.facebook.com/VacunateRD/"
        target="__blank"
        aria-label="Facebook"
        style={{ margin: "10px" }}
      >
        <FacebookIcon style={{ fontSize: "40px", color: "#00205C" }} />
      </IconButton>
      <IconButton
        href="mailto: info@ministeriodesalud.gob.do"
        aria-label="Email"
        style={{ margin: "10px" }}
      >
        <EmailIcon style={{ fontSize: "40px", color: "#00205C" }} />
      </IconButton>
      <IconButton
        href="tel: +1 809 541 3121"
        aria-label="Telefono"
        style={{ margin: "10px" }}
      >
        <PhoneIcon style={{ fontSize: "40px", color: "#00205C" }} />
      </IconButton>
    </>
  );
}
