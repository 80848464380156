import axios from "axios";
const apiKey = process.env.REACT_APP_X_API_KEY;

export async function checkOpenTickets(token, cedula) {
  const data = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/tickets/check_open_ticket?cedula=${cedula}`,
    headers: { 
      Authorization: "Bearer " + token,
      "X-API-KEY": apiKey
    },
  });
  return data.data;
}
