import { TYPES } from "../../types/types";

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES.Salve:
      return {
        nombre: action.payload.nombre,
        cedula: action.payload.cedula,
        tokenP: action.payload.token

      }
    case TYPES.login:
      return {
        statusPassport: action.payload.status,
        token: action.payload.token,
        passport: action.payload.passport,
      };
    case TYPES.openTickets:
      return {
        open: action.payload.openTickets
      };
    case TYPES.logout:
      return {};

    default:
      return state;
  }
};
