import axios from "axios";
const apiKey = process.env.REACT_APP_X_API_KEY;

export async function getPdf(token) {
  const data = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/pdf-generator`,
    headers: { 
      Authorization: "Bearer " + token,
      "X-API-KEY": apiKey
    },
    responseType: "blob",
  });
  return data.data;
}

