import React, { useEffect, useState} from "react";
import { Box, Button, Container, Modal, Typography } from "@material-ui/core";
import { useStyles } from "./layout.styles";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import useStrapi from "../../hooks/useStrapi";
import { useDispatch, useSelector } from "react-redux";
import { getStrapiData, getStrapiError} from "../../Redux/actions/actionStrapiData";
import { removeError500 } from "../../Redux/actions/uiError";
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const Layout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const { error500 } = useSelector((state) => state.uiError)
  const [isError500, setIsError500] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if(error500){
      setIsError500(true)
    }
  }, [error500])

  const handleCloseModal = () => {
    setIsError500(false)
    dispatch(removeError500())
  }

  useEffect(() => {
    if (process.env.REACT_APP_WORKSPACE === "production") {
      ReactGA.pageview(location.pathname);
    }
  }, [location.pathname]);

  return (
    <div className={classes.main}>
      <Container className={classes.container}>{children}</Container>
      <Modal className={classes.modal_error} open={isError500}>
        <Box className={classes.modal_box}>          
          <ErrorOutlineIcon className={classes.error_icon} />
          <Typography className={classes.modal_text}>
            {error500}
          </Typography>
          <CloseIcon onClick={handleCloseModal} className={classes.close_icon} />
        </Box>
      </Modal>
    </div>
  );
};
