import { TYPES } from "../../types/types";

export const strapiReducer = (state = {}, {type, payload}) => {
  switch (type) {
    case TYPES.strapiData:
      return {
        strapiData: payload.strapiData
      };

    case TYPES.strapiError:
      return {
        ...state,
        strapiError: payload.strapiError
      };

    default:
      return state;
  }
};
