import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    textAlign: "center",
    alignItems: "center",
    color: "white",
    padding: "36px",
    "@media screen and (max-width: 412px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  logo: {
    width: "100%",
    padding: "3rem 0 1rem 0",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#00205b",
  },
  info: {
    margin: "1rem",
    color: "#00205b",
  },
  support_form: {
    width: "90%",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  select: {
    width: "100%",
    margin: "0.7rem 0",
    textAlign: "center",
  },
  select_input:{
    display: 'flex'
  },
  type_color: {
    color: "#00205b",
    fontSize: "14px",
    fontWeight: "bold",
  },
  anexar: {
    color: "#8080806e",
  },
  error_color: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  fontsize: {
    fontSize: "14px",
    fontWeight: "normal",
  },
  buttons_content: {
    "@media screen and (max-width: 470px)": {
      flexDirection: "column",
    },
  },
  support_button: {
    "@media screen and (max-width: 350px)": {
      margin: "0 0 1rem 0",
    },
    color: "#00205b",
    backgroundColor: "white",
    border: "2px solid #00205b",
    borderRadius: "0.5rem",
    textTransform: "none",
  },
  reportar: {
    textTransform: "none",
    color: "white",
    backgroundColor: "#00205b",
    borderRadius: "0.5rem",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#00207b",
    },
  },
  button: {
    minWidth: "150px",
    height: "45px",
    fontSize: "18px",
    fontWeight: "bold",
    "@media screen and (max-width: 470px)": {
      width: "100%",
      marginTop: "1rem",
    },
  },
  modalEnviar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boxModalEnviar: {
    margin: "1rem",
    width: "500px",
    minHeight: "200px",
    backgroundColor: "white",
    color: "white",
    borderRadius: "0.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #00205b",
  },
  modal_content: {
    width: "80%",
    margin: "1rem",
  },
  modal_text: {
    fontWeight: "bold",
    color: "#00205b",
  },
  modal_box_button: {
    width: "100%",
    display: "flex",
    justifyContent: "right",
    marginTop: "1.5rem",
  },
  modal_button: {
    textTransform: "none",
    color: "white",
    backgroundColor: "#00205b",
    borderRadius: "0.5rem",
    fontWeight: "bold",
    minWidth: "150px",
    height: "45px",
    fontSize: "18px",
    "@media screen and (max-width: 470px)": {
      width: "100%",
      margin: "1rem 0",
    },
    "&:hover": {
      backgroundColor: "#00207b",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFF",
  },
  helperText: {
    marginTop: "5px",
    marginLeft: "2px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  gridError:{
    marginTop: "20px"
  },
}));
